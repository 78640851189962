export const findErrorField = (e, callback) => {
    if (
        e.response &&
        e.response.data &&
        e.response.data.type === 'VALIDATION_ERROR'
    ) {
        for (let property in e.response.data.errors) {
            callback(property, e.response.data.errors[property]['message']);
        }
    } else {
        //TODO:
        // setErrorState(e);
    }
};
