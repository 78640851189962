import * as Yup from 'yup';

function emailCustom() {
    return this.test({
        name: 'emailCustom',
        exclusive: false,
        message: 'Please enter a valid e-mail address',
        test: function(value) {
            if (value) {
                let i = value.indexOf('@');
                return (
                    value.substr(0, i).length > 2 ||
                    value.substr(i, value.length).length > 2
                );
            }
        },
    });
}

function phoneCustom() {
    return this.test({
        name: 'phoneCustom',
        exclusive: false,
        message: 'Please enter a valid U.S. phone number',
        test: function(value) {
            if (value) {
                const regExp = new RegExp('^[0-9]{3}-*[0-9]{3}-*[0-9]{4}$');
                return regExp.test(value);
            }
        },
    });
}

function passwordCustom() {
    return this.test({
        name: 'passwordCustom',
        exclusive: false,
        message:
            'Password must be at least 8 characters and have at least 1 number',
        test: function(value) {
            if (value) {
                const regExp = new RegExp(
                    '(?=^.{8,}$)(?=.*\\d)(?![.\\n])(?=.*[A-Za-z])'
                );
                return regExp.test(value);
            }
        },
    });
}

Yup.addMethod(Yup.string, 'emailCustom', emailCustom);
Yup.addMethod(Yup.string, 'phoneCustom', phoneCustom);
Yup.addMethod(Yup.string, 'passwordCustom', passwordCustom);

export const personalDataSchema = Yup.object().shape({
    firstName: Yup.string()
        .required('Required')
        .min(
            2,
            "First Name must be between 1 and 40 characters (a-Z, ', -, and spaces)"
        )
        .max(
            39,
            "First Name must be between 1 and 40 characters (a-Z, ', -, and spaces)"
        )
        .matches(
            /^[a-zA-Z\s\-?'?"]*$/,
            "First Name must be between 1 and 40 characters (a-Z, ', -, and spaces)"
        ),
    lastName: Yup.string()
        .required('Required')
        .min(
            2,
            "Last Name must be between 1 and 40 characters (a-Z, ', -, and spaces)"
        )
        .max(
            39,
            "Last Name must be between 1 and 40 characters (a-Z, ', -, and spaces)"
        )
        .matches(
            /^[a-zA-Z\s\-?'?"]*$/,
            "Last Name must be between 1 and 40 characters (a-Z, ', -, and spaces)"
        ),
    email: Yup.string()
        .required('Required')
        .email('Please enter a valid e-mail address')
        .emailCustom(),
    phone: Yup.string()
        .required('Required')
        .phoneCustom(),
});
