import ConversationItemOption from './ConversationItemOption';
import { isThirdPerson, getSubdomain } from '../conversationState';

class ConversationItem {
    constructor({ entry }) {
        const {
            buttonLabel,
            config,
            decisionType,
            description,
            description3rdPerson,
            iconUrl,
            illustrationUrl,
            isActive,
            name,
            name3rdPerson,
            nextConversationItemObj,
            options,
            redirectUrl,
            type,
        } = entry.fields;

        this.buttonLabel = buttonLabel;
        this.config = config;
        this.iconUrl = iconUrl;
        this.illustrationUrl = illustrationUrl;
        this.isActive = isActive;
        this.name = name;
        this.name3rdPerson = name3rdPerson;
        this.decisionType = decisionType;
        this.type = type;
        this.redirectUrl = redirectUrl;

        this.description = this.parseDescription(description);

        this.description3rdPerson = description3rdPerson
            ? description3rdPerson.content[0].content[0].value
            : '';

        this.options = options
            ? options.map(option =>
                  option.sys.contentType.sys.id === 'conversationItem'
                      ? new ConversationItem({ entry: option })
                      : new ConversationItemOption({ entry: option })
              )
            : [];
        const subdomain = getSubdomain();
        if (nextConversationItemObj) {
            this.nextConversationItemId = subdomain
                ? nextConversationItemObj[subdomain]
                : nextConversationItemObj['em'];
            this.skip = nextConversationItemObj['skip'];
        }

        this.id = entry.sys.id;
    }

    parseDescription = description => {
        if (
            !description ||
            !description.content ||
            !description.content[0] ||
            !description.content[0].content ||
            !description.content[0].content[0]
        ) {
            return '';
        } else {
            if (description.content.length === 1) {
                return description.content[0].content[0].value;
            } else {
                let returnValue = '';
                description.content.forEach(content => {
                    if (content.nodeType === 'paragraph') {
                        returnValue += content.content[0].value + '\n';
                    }
                });
                return returnValue;
            }
        }
    };

    getName = () => {
        return isThirdPerson() && this.name3rdPerson
            ? this.name3rdPerson
            : this.name;
    };

    getDescription = () => {
        return isThirdPerson() && this.description3rdPerson
            ? this.description3rdPerson
            : this.description;
    };
}

export default ConversationItem;
