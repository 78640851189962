import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Button from '../../components/shared/Button/Button.js';

import './index.scss';

const FinalPage = ({
    title,
    yellowParagraph,
    paragraph,
    buttonCallback,
    buttonText,
    intl,
}) => {
    return (
        <div className="final-page-wrapper">
            <div className="content">
                <h1>{title}</h1>
                <div className="yellow-paragraph">
                    <p>{yellowParagraph}</p>
                </div>
                <p>{paragraph}</p>
                <Button
                    buttonType="rounded-transparent"
                    text={
                        typeof buttonText === 'object'
                            ? intl.formatMessage(buttonText.props)
                            : buttonText
                    }
                    callback={buttonCallback}
                    style={{ fontSize: 20 }}
                />
            </div>
        </div>
    );
};

FinalPage.propTypes = {
    title: PropTypes.string,
    yellowParagraph: PropTypes.string,
    paragraph: PropTypes.string,
};

export default injectIntl(FinalPage);
