import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import services from '../../../services';
import CustomCheckbox from '../../shared/Checkbox/CustomCheckbox';
import CssBaseline from '@material-ui/core/CssBaseline';
import { get } from 'lodash';

const QuestionWrapper = styled.div``;

class ConversationHostComponent extends Component {
    constructor(props) {
        super(props);

        let options = {};
        props.data.options.forEach(row => {
            options[row.id] = false;
        });

        this.state = {
            selectedOptions: options,
            showButton: false,
            disableCheckboxes: false,
        };

        this.handleFooterProps();
    }

    handleChange = event => {
        let { value } = event.target;
        this.setState(
            ({ selectedOptions }) => ({
                selectedOptions: {
                    ...selectedOptions,
                    [value]: !selectedOptions[value],
                },
            }),
            () => {
                const {
                    data: {
                        config: { acceptMin, acceptMax },
                    },
                } = this.props;

                if (acceptMin === 0) {
                    this.setState(
                        {
                            showButton: true,
                        },
                        this.handleFooterProps()
                    );
                }

                if (
                    this.countCheckedOptions().toString() ===
                    acceptMax.toString()
                ) {
                    this.setState(
                        {
                            disableCheckboxes: true,
                        },
                        () => {
                            setTimeout(this.setOption(this.props.data), 2000);
                        }
                    );
                }

                if (
                    this.countCheckedOptions() >= acceptMin &&
                    this.countCheckedOptions() <= acceptMax
                ) {
                    this.setState(
                        {
                            showButton: true,
                        },
                        this.handleFooterProps
                    );
                } else {
                    this.setState(
                        {
                            showButton: false,
                        },
                        this.handleFooterProps
                    );
                }
            }
        );
    };

    countCheckedOptions = () => {
        let count = 0;

        for (let prop in this.state.selectedOptions) {
            if (this.state.selectedOptions[prop] === true) {
                count++;
            }
        }

        return count;
    };

    setOption = async () => {
        const { goToNextQuestion, data } = this.props;
        const { conversationItemId } = data;
        const nextConvo = get(data, 'edges[0].nextConversationItemId');
        let answerObjs = [];
        for (const conversationItemOptionId of Object.keys(
            this.state.selectedOptions
        )) {
            const answer = this.state.selectedOptions[conversationItemOptionId];
            if (answer) {
                answerObjs.push({
                    conversationItemId,
                    conversationItemOptionId,
                });
            }
        }

        if (answerObjs.length > 0) {
            try {
                await services.conversation.saveResponse({
                    questionId: conversationItemId,
                    answer: answerObjs,
                });
            } catch (e) {
                console.log(e);
            }
        }

        goToNextQuestion({ id: nextConvo });
    };

    handleFooterProps = () => {
        const footerProps = {
            nextButtonText: 'Continue',
            hasNextButton: this.state.showButton,
            nextCallback: () => this.setOption(this.props.data),
        };
        this.props.triggerFooterUpdate(footerProps);
    };

    render() {
        let cbHolderClass = 'cb-holder';

        if (this.props.data.options.length <= 3) {
            cbHolderClass += ' full-width';
        }

        return (
            <QuestionWrapper
                className={`cb-wrapper ${
                    this.props.data.options.length > 3 ? '' : 'single-column'
                }`}
            >
                <CssBaseline />
                {this.props.data.options.map(row => {
                    return (
                        <div key={row.id} className={cbHolderClass}>
                            <CustomCheckbox
                                label={row.option}
                                onChange={this.handleChange}
                                checked={this.state.selectedOptions[row.id]}
                                value={row.id.toString()}
                                disabled={this.state.disableCheckboxes}
                            />
                        </div>
                    );
                })}
            </QuestionWrapper>
        );
    }
}

export default withRouter(ConversationHostComponent);
