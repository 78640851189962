import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { get, startCase, toLower } from 'lodash';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getGeoState } from '../../../conversationState';
import services from '../../../services';

class Pharmacy extends Component {
    constructor(props) {
        super(props);

        const footerProps = {
            hasNextButton: true,
            nextCallback: this.nextClick,
            nextButtonText: 'Next',
        };
        this.state = {
            pharmacies: [],
        };

        props.triggerFooterUpdate(footerProps);
    }

    async componentDidMount() {
        await this.fetchPharmacies();
    }

    fetchPharmacies = async () => {
        try {
            const zipCode = await getGeoState();
            const { data } = await services.pharmacy.search(zipCode);
            this.setState({ pharmacies: data });
        } catch (e) {
            console.log('Could not find zip code');
        }
    };

    nextClick = () => {
        const { goToNextQuestion, data } = this.props;
        const nextConvo = get(data, 'edges[0].nextConversationItemId');

        goToNextQuestion({ id: nextConvo });
    };

    formatOptionLabel = option => {
        const formattedstreet = startCase(toLower(option.Address));
        const formattedCity = startCase(toLower(option.City));
        return `${option.Name}, ${formattedstreet}, ${formattedCity}`;
    };

    formattedSelection = option => {
        const formattedstreet = startCase(toLower(option.Address));
        const formattedCity = startCase(toLower(option.City));
        return `${option.Name}, ${formattedstreet}, ${formattedCity}, ${option.Zip}`;
    };

    pharmacySelected = async (event, value) => {
        const formattedPharmacy = this.formattedSelection(value);
        const { goToNextQuestion, data } = this.props;
        const nextConversationItemId = get(
            data,
            'edges[0].nextConversationItemId'
        );
        const { conversationItemId } = this.props.data;
        try {
            await services.conversation.saveResponse({
                questionId: conversationItemId,
                answer: [
                    {
                        conversationItemId,
                        answer: formattedPharmacy,
                    },
                ],
            });
            goToNextQuestion({ id: nextConversationItemId });
        } catch (e) {
            console.log(e);
        }
    };

    render() {
        return (
            <div>
                <Autocomplete
                    id="combo-box-demo"
                    options={this.state.pharmacies}
                    getOptionLabel={option => this.formatOptionLabel(option)}
                    onChange={this.pharmacySelected}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label="Search Nearby Pharmacies"
                        />
                    )}
                />
            </div>
        );
    }
}

export default withRouter(Pharmacy);
