import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const ErrorMessage = ({ isShown, text }) => (
    <React.Fragment>
        {isShown && (
            <p className="error-message" data-test-id="errorMessage">
                {text}
            </p>
        )}
    </React.Fragment>
);

ErrorMessage.propTypes = {
    text: PropTypes.string.isRequired,
    isShown: PropTypes.bool.isRequired,
};

export default ErrorMessage;
