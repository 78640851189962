import API from './api';

export const findPlans = planToken =>
    new Promise((resolve, reject) => {
        API.post('/plan/conversation-find', {
            planToken,
        })
            .then((res, err) => {
                if (err) {
                    return reject('Error finding plans');
                }
                return resolve(res.data);
            })
            .catch(err => {
                reject(err.response ? err.response.data : err);
            });
    });

export const getQuoteData = accessToken =>
    new Promise((resolve, reject) => {
        API.post(
            '/quote/create-from-employee-center',
            {},
            {
                headers: { Authorization: accessToken },
            }
        )
            .then((res, err) => {
                if (err) {
                    return reject('Error finding plans');
                }
                return resolve(res.data);
            })
            .catch(err => {
                reject(err.response ? err.response.data : err);
            });
    });
