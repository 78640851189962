import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { fetchUserBroker } from '../../../services/user';
import queryString from 'query-string';
import { get } from 'lodash';
import { getEnrolledInMedicare } from '../../../conversationState';
class Calendly extends Component {
    constructor(props) {
        super(props);

        const footerProps = {
            hasNextButton: true,
            nextCallback: this.bookLater,
            nextButtonText: 'Next',
        };

        this.state = {
            broker: {},
            alreadyEnrolledInMedicare: false,
        };

        props.triggerFooterUpdate(footerProps);
    }
    async componentDidMount() {
        const { accessToken } = queryString.parse(this.props.location.search);
        const result = await fetchUserBroker(accessToken);
        const enrolledStatus = getEnrolledInMedicare();
        this.setState({
            broker: result,
            alreadyEnrolledInMedicare: enrolledStatus ? true : false,
        });
        if (enrolledStatus) {
            let enrolledFooterProps = {
                bookFirst: true,
                bookNowCallback: this.bookNow,
                bookLaterCallback: this.bookLater,
            };

            this.props.triggerFooterUpdate(enrolledFooterProps);
        } else {
            this.bookLater({ normalNext: true });
        }
    }

    bookNow = () => {
        const { goToNextQuestion, data } = this.props;
        const nextConvo = get(data, 'edges[0].nextConversationItemId');
        goToNextQuestion({ id: nextConvo, bookFirst: true, redirectUrl: '' });
    };

    bookLater = ({ normalNext }) => {
        const { goToNextQuestion, data } = this.props;
        const nextConvo = get(data, 'edges[0].nextConversationItemId');
        if (normalNext) {
            goToNextQuestion({
                id: nextConvo,
                redirectUrl: get(data, 'redirectUrl'),
            });
        } else {
            goToNextQuestion({
                id: nextConvo,
                bookFirst: false,
                redirectUrl: get(data, 'redirectUrl'),
            });
        }
    };

    render() {
        const { alreadyEnrolledInMedicare } = this.state;
        return (
            <div>
                {alreadyEnrolledInMedicare && (
                    <div>
                        Would you like to book an appointment now, or see plans
                        first?
                    </div>
                )}
            </div>
        );
    }
}

export default withRouter(Calendly);
