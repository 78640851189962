import { useState, useRef, useEffect } from 'react';

export function useHover() {
    const [isHovered, setIsHovered] = useState(false);

    const hoverRef = useRef(null);

    const handleMouseOver = () => setIsHovered(true);
    const handleMouseOut = () => setIsHovered(false);

    useEffect(
        () => {
            const elem = hoverRef.current;
            if (elem) {
                elem.addEventListener('mouseover', handleMouseOver);
                elem.addEventListener('mouseout', handleMouseOut);

                return () => {
                    elem.removeEventListener('mouseover', handleMouseOver);
                    elem.removeEventListener('mouseout', handleMouseOut);
                };
            }
        },
        // eslint-disable-next-line
        [hoverRef.current]
    ); // Recall only if ref changes

    return [hoverRef, isHovered];
}

export function useInterval(callback, delay) {
    const savedCallback = useRef(null);

    useEffect(() => {
        savedCallback.current = callback;
    });

    useEffect(() => {
        function handler() {
            savedCallback.current();
        }
        let intervalId = null;
        if (delay > 0) {
            intervalId = setInterval(handler, delay);
        }
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
                intervalId = null;
            }
        };
    }, [delay]);
}

export function usePaging(totalPages, defaultPage = 1) {
    let [currentPage, setCurrentPage] = useState(defaultPage);

    let goBack = () => {
        let newPage = currentPage - 1;
        if (newPage < 1) newPage = totalPages;
        setCurrentPage(newPage);
    };
    let goForward = () => {
        let newPage = currentPage + 1;
        if (newPage > totalPages) newPage = 1;
        setCurrentPage(newPage);
    };
    let goTo = pageNumber => {
        if (pageNumber > totalPages) pageNumber = totalPages;
        if (pageNumber < 1) pageNumber = 1;
        setCurrentPage(pageNumber);
    };

    return {
        currentPage,
        goForward,
        goBack,
        goTo,
    };
}
