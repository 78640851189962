import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import ReactPlayer from 'react-player';

class Video extends Component {
    constructor(props) {
        super(props);

        const footerProps = {
            hasNextButton: true,
            nextCallback: this.nextClick,
            nextButtonText: 'Next',
            skipRestAndFinish: true,
            finishConversationCallback: this.skipOnboarding,
        };

        this.playerRef = React.createRef();

        props.triggerFooterUpdate(footerProps);
        this.videoLink = get(this, 'props.data.buttonLabel', '');
    }

    skipOnboarding = () => {
        const { finishConversationCallback } = this.props;
        console.log('about to skip onboarding', this.props);
        finishConversationCallback({ skipOnboarding: true });
    };

    nextClick = () => {
        const { goToNextQuestion, data } = this.props;
        const nextConvo = get(data, 'edges[0].nextConversationItemId');

        goToNextQuestion({ id: nextConvo });
    };

    render() {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ReactPlayer
                    ref={this.playerRef}
                    url={this.videoLink}
                    autoplay
                    controls={true}
                />
            </div>
        );
    }
}

export default withRouter(Video);
