import API from './api';

const findDoctor = async ({ zipCode, searchTerm, accessToken }) => {
    try {
        let url = `/doctor?term=${searchTerm}`;
        if (zipCode) {
            url = url + `&zipCode=${zipCode}`;
        }
        const result = await API.get(url, {
            headers: { Authorization: accessToken },
        });
        return result;
    } catch (e) {
        return [];
    }
};

export default { findDoctor };
