import API from './api';
import setConversationState from '../conversationState';

/*
    If a user is logged in -> save their response
    else save it in conversationState and submit with personal data (Personal data component)
*/
const saveResponse = async data => {
    setConversationState(data);
};

export const getConversationByUrlParam = async () => {
    const params = new URLSearchParams(window.location.search);
    const conversationId = params.get('conversationId');
    if (conversationId) {
        try {
            const { data } = await API.get(
                `/conversation/get-conversation/${conversationId}`
            );
            return data;
        } catch (e) {
            return null;
        }
    } else {
        return null;
    }
};

export const getConversationBySubdomain = async () => {
    try {
        const { data } = await API.get(
            '/conversation/get-conversation-by-subdomain'
        );
        return data;
    } catch (e) {
        return { error: e };
    }
};

export const getConversationRouting = async conversationId => {
    try {
        const { data } = await API.get(
            `/conversation/routing/${conversationId}`
        );
        return data;
    } catch (e) {
        return { error: e };
    }
};

const isEligible = async bday => {
    const response = await API.get(
        `/conversation/is-eligible?birthDate=${bday}`
    );
    if (response.status >= 400) {
        throw new Error('Failed to validate eligibility');
    }

    return response.data;
};

const getClientObj = async () => {
    const response = await API.get('/client');
    if (response.status > 400) {
        throw new Error('Failed to fetch client obj');
    }
    return response;
};

const reviewYourInfo = async planToken => {
    let url = '/conversation/review-your-info';
    if (planToken) {
        url = url + `?planToken=${planToken}`;
    }

    const response = await API.get(url);

    if (response.status >= 400) {
        throw new Error('Failed to fetch info');
    }
    return response.data;
};

export default {
    isEligible,
    reviewYourInfo,
    saveResponse,
    getClientObj,
};
