import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { TextField } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import services from '../../../services';
import styled from 'styled-components';

const HiddenInputs = styled.div`
    display: none;
`;

class Text extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: '',
        };

        const footerProps = {
            hasNextButton: true,
            nextCallback: this.saveSelection,
            nextButtonText: 'Next',
        };
        props.triggerFooterUpdate(footerProps);
    }

    componentDidMount() {
        if (this.props.prefillData) {
            this.setState({ text: this.props.prefillData });
        }
    }

    saveSelection = async () => {
        const { goToNextQuestion, data } = this.props;
        const { conversationItemId } = data;
        const answer = this.state.text;
        const nextConvo = get(data, 'edges[0].nextConversationItemId');

        try {
            await services.conversation.saveResponse({
                questionId: conversationItemId,
                answer: [
                    {
                        conversationItemId,
                        answer,
                    },
                ],
            });
            goToNextQuestion({ id: nextConvo });
        } catch (e) {
            console.log(e);
        }
    };
    handleChange = newVal => {
        this.setState({ text: newVal });
    };

    hiddenComponents = () => {
        return (
            <HiddenInputs>
                <input id="name" type="text" name="name" />
                <input id="firstName" type="text" name="firstName" />
                <input id="lastName" type="text" name="lastName" />
            </HiddenInputs>
        );
    };

    maybeRenderIllustration = () => {
        const illustrationUrl = this.props.data.illustrationUrl;
        return illustrationUrl ? (
            <IllustrationImg
                src={illustrationUrl.fields.file.url}
                alt="illustration"
            />
        ) : null;
    };

    render() {
        const { mask, fullWidth } = this.props;
        const fsmask = '.fs-mask';
        return (
            <>
                {/* To avoid autocomplete */}
                {this.hiddenComponents()}
                <TextField
                    autoComplete={'false'}
                    variant={'standard'}
                    className={fsmask}
                    fullWidth={fullWidth}
                    value={this.state.text}
                    onKeyPress={e => {
                        if (e.key === 'Enter' && this.state.text) {
                            this.saveSelection();
                        }
                    }}
                    onChange={e => this.handleChange(e.target.value)}
                    InputProps={
                        mask
                            ? {
                                  inputComponent: mask,
                              }
                            : undefined
                    }
                    id="userInput"
                />
                {/* {this.maybeRenderIllustration()} */}
            </>
        );
    }
}

const IllustrationImg = styled.img`
    float: right;
    margin-top: 50px;
    max-width: 500px;
    height: auto;
`;

export default withRouter(injectIntl(Text));
