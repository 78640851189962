import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import services from '../../../services';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import MaskedInput from 'react-text-mask';
import Input from '../../shared/Input/Input';
import ErrorMessage from '../../shared/ErrorMessage/ErrorMessage';
import { Grid } from '@material-ui/core';
import { get } from 'lodash';

const DATE_FORMAT = 'MM/DD/YYYY';
const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy');
const TIMEOUT = 1000;

const Enrollment = ({ data, goToNextQuestion, intl, triggerFooterUpdate }) => {
    const [partAEnrollmentDate, setPartAEnrollmentDate] = useState('');
    const [partBEnrollmentDate, setPartBEnrollmentDate] = useState('');
    const [invalidADate, setInvalidADate] = useState(null);
    const [invalidBDate, setInvalidBDate] = useState(null);

    const footerProps = {
        hasNextButton: false,
    };

    useEffect(() => {
        triggerFooterUpdate(footerProps);
        // eslint-disable-next-line
    }, []);

    const generateMask = userInput => {
        const monthReg = /^[1-9]\/$/;
        const dayReg = /^\d{2}\/[1-9]\/$/;
        if (userInput && userInput.length === 2 && monthReg.test(userInput)) {
            return [/\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
        } else if (
            userInput &&
            userInput.length === 5 &&
            dayReg.test(userInput)
        ) {
            return [/\d/, /\d/, '/', /\d/, '/', /\d/, /\d/, /\d/, /\d/];
        } else {
            return [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
        }
    };

    const onChangeDate = async (e, type) => {
        let {
            target: { value },
        } = e;

        let setEnrollmentDateFunction;
        let enrollmentDate;
        let setInvalidDateFunction;
        let invalidDate;
        if (type === 'a') {
            setEnrollmentDateFunction = setPartAEnrollmentDate;
            enrollmentDate = partAEnrollmentDate;
            setInvalidDateFunction = setInvalidADate;
            invalidDate = invalidADate;
        } else if (type === 'b') {
            setEnrollmentDateFunction = setPartBEnrollmentDate;
            enrollmentDate = partBEnrollmentDate;
            setInvalidDateFunction = setInvalidBDate;
            invalidDate = invalidBDate;
        }

        if (!value || (value === enrollmentDate && value.length !== 10)) {
            setEnrollmentDateFunction(value);
            return setInvalidDateFunction(
                intl.formatMessage({
                    id: 'birthDate.numericError',
                })
            );
        }
        if (value && value !== enrollmentDate && invalidDate) {
            setInvalidDateFunction(null);
        }

        // adding a trailing forward slash (only necessary for single digit days/months)
        // necessary because autoCorrectedDatePipe doesn't recognize the datemask additions for appending the forward slash
        if (
            (value &&
                value.length === 2 &&
                ![1, 0].includes(parseInt(value.slice(-1)))) ||
            (value &&
                value.length === 5 &&
                ![1, 0].includes(parseInt(value.slice(-1))))
        ) {
            // If a user types "[1-9]/" then add a zero in front; length 2 is for month, length 5 is for day
            if (value.slice(-1) === '/') {
                if (value.length === 2) {
                    setEnrollmentDateFunction(`0${value}/`);
                } else if (value.length === 5) {
                    // insert a '0' in front of the day
                    setEnrollmentDateFunction(
                        value.substring(0, 2) + '0' + value.substring(2)
                    );
                }
                return;
            } else {
                setEnrollmentDateFunction(`${value}/`);
                return;
            }
        }

        setEnrollmentDateFunction(value);

        if (value.length === 10 && moment(value, DATE_FORMAT).isValid()) {
            const dateToUse = value;
            let partADate;
            let partBDate;
            if (type === 'a') {
                partADate = moment(dateToUse, DATE_FORMAT).format('MM-DD-YYYY');
                partBDate = partBEnrollmentDate;
            } else if (type == 'b') {
                partADate = partAEnrollmentDate;
                partBDate = moment(dateToUse, DATE_FORMAT).format('MM-DD-YYYY');
            }

            const replacementKey = '{enrollmentTime}';
            // const nextConvo = data.edges[0].nextConversationItemId;
            const { conversationItemId, id } = data;
            setTimeout(async () => {
                try {
                    await services.conversation.saveResponse({
                        questionId: conversationItemId || id,
                        answer: [
                            {
                                conversationItemId: conversationItemId || id,
                                answer: JSON.stringify({
                                    partAEnrollmentDate: partADate,
                                    partBEnrollmentDate: partBDate,
                                }),
                            },
                        ],
                        replacementKey,
                        replacementValue: {
                            partAEnrollmentDate: partADate,
                            partBEnrollmentDate: partBDate,
                        },
                    });
                } catch (e) {
                    console.log(e);
                }

                if (partAEnrollmentDate && partBEnrollmentDate) {
                    let nextConversation = get(
                        data,
                        'edges[0].nextConversationItemId'
                    );
                    if (nextConversation) {
                        goToNextQuestion({ id: nextConversation });
                    } else {
                        goToNextQuestion({ id: null });
                    }
                }
            }, TIMEOUT);
        } else {
            if (value.length === 10) {
                setInvalidDateFunction(true);
            }
        }
    };

    return (
        <div>
            <Grid container>
                <Grid xs={3}>Part A Enrollment Date:</Grid>
                <Grid xs={9}>
                    <MaskedInput
                        mask={generateMask}
                        placeholder={DATE_FORMAT}
                        pipe={autoCorrectedDatePipe}
                        keepCharPositions={true}
                        guide={false}
                        onChange={e => onChangeDate(e, 'a')}
                        placeholderChar={' '}
                        value={partAEnrollmentDate}
                        render={(ref, props) => (
                            <>
                                <Input
                                    ref={ref}
                                    callback={props.onChange}
                                    value={props.valueDefault}
                                    focusOnLoad={true}
                                    name="partAEnrollmentDate"
                                    {...props}
                                />
                                {invalidADate ? (
                                    <ErrorMessage
                                        isShown={!!invalidADate}
                                        text={invalidADate ? invalidADate : ''}
                                    />
                                ) : null}
                            </>
                        )}
                    />
                </Grid>

                <Grid xs={3}>Part B Enrollment Date:</Grid>
                <Grid xs={9}>
                    <MaskedInput
                        mask={generateMask}
                        placeholder={DATE_FORMAT}
                        pipe={autoCorrectedDatePipe}
                        keepCharPositions={true}
                        guide={false}
                        onChange={e => onChangeDate(e, 'b')}
                        placeholderChar={' '}
                        value={partBEnrollmentDate}
                        render={(ref, props) => (
                            <>
                                <Input
                                    ref={ref}
                                    callback={props.onChange}
                                    value={props.valueDefault}
                                    name="partBEnrollmentDate"
                                    {...props}
                                />
                                {invalidBDate ? (
                                    <ErrorMessage
                                        isShown={!!invalidBDate}
                                        text={invalidBDate ? invalidBDate : ''}
                                    />
                                ) : null}
                            </>
                        )}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

Enrollment.propTypes = {};
Enrollment.defaultProps = {};

export default withRouter(injectIntl(Enrollment));
