import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { get } from 'lodash';
import Button from '../../../components/shared/Button/Button';
import services from '../../../services';
import { getEntry } from '../../../services/contentful';

class ConversationHostComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: [],
            answerId: '',
            selectedValue: '',
        };

        this.generateButtonSize = () => {
            const longestOptionLength = props.data.options
                .map(option => option.option)
                .reduce((a, b) => (a.length > b.length ? a : b), '').length;

            return longestOptionLength >= 24 ? 'large' : 'medium';
        };

        this.buttonSize = this.generateButtonSize();

        const footerProps = {
            hasNextButton: false,
        };
        props.triggerFooterUpdate(footerProps);
    }

    setOptions = async () => {
        if (Object.prototype.hasOwnProperty.call(this.props.data, 'edges')) {
            const promiseArray = this.props.data.edges.map(edge => {
                return getEntry({ id: edge.conversationItemOptionId });
            });
            const entries = await Promise.all(promiseArray);
            const options = entries.map((entry, i) => {
                const meta = JSON.parse(this.props.data.edges[i].meta);
                //the split grabs the id from the stringified meta, eg: item_2w6M6JXK2SPt1DU8ThKCmB
                return {
                    ...entry,
                    ...this.props.data.edges[i],
                    meta,
                    target: meta.target.split('_')[1],
                    source: meta.source.split('_')[1],
                };
            });
            this.setState({ options });
        }
    };

    componentDidMount() {
        this.setOptions();
    }

    saveOption = ({ answer }) => {
        const { goToNextQuestion, isGroupQuestion, data } = this.props;
        const { conversationItemOptionId, nextConversationItemId } = answer;
        const { conversationItemId } = data;

        if (!isGroupQuestion) {
            setTimeout(async () => {
                let replacementKey;
                let replacementValue;
                if (get(answer, 'premiumValue')) {
                    replacementKey = '{premiumValue}';
                    replacementValue = answer.premiumValue.toFixed(2);
                }

                const forSomeoneElse = answer.forSomeoneElse;

                try {
                    await services.conversation.saveResponse({
                        questionId: conversationItemId,
                        answer: [
                            {
                                conversationItemId,
                                conversationItemOptionId,
                            },
                        ],
                        replacementKey,
                        replacementValue,
                        forSomeoneElse,
                    });
                } catch (e) {
                    console.log(e);
                }
                goToNextQuestion({
                    id: nextConversationItemId,
                });
            }, 400);
        } else {
            this.props.validateGroupQuestion({
                questionId: conversationItemId,
                answerId: conversationItemOptionId,
            });

            this.setState({
                answerId: conversationItemOptionId,
            });
        }
    };

    buttonClassName(buttonId) {
        let className = 'questionButton';

        if (this.props.isGroupQuestion && this.state.answerId === buttonId) {
            className += ' clicked';
        }

        return className;
    }

    buttonsPartial() {
        const { options } = this.state;
        return options.length
            ? options.map((option, index) => {
                  return (
                      <Button
                          key={index}
                          text={option.fields.option}
                          callback={() => this.saveOption({ answer: option })}
                          customClass={this.buttonClassName(option.id)}
                          buttonType="rect-transparent"
                          size={this.buttonSize}
                      />
                  );
              })
            : null;
    }

    dropDownPartial() {
        const { options } = this.state;
        return (
            <Select
                displayEmpty
                value={this.state.selectedValue}
                onChange={event => {
                    this.setState({
                        selectedValue: event.target.value,
                    });
                    const option = options.find(item => {
                        return item.id === event.target.value;
                    });
                    this.saveOption({ answer: option });
                }}
            >
                <MenuItem key={''} value={''} disabled selected hidden>
                    {'Select Option'}
                </MenuItem>
                {options.map(row => {
                    return (
                        <MenuItem key={row.id} value={row.id}>
                            {row.meta.label}
                        </MenuItem>
                    );
                })}
            </Select>
        );
    }

    render() {
        const { data } = this.props;
        return (
            <>
                {Object.prototype.hasOwnProperty.call(data, 'edges') &&
                data.edges.length > 6
                    ? this.dropDownPartial()
                    : this.buttonsPartial()}
            </>
        );
    }
}

export default withRouter(ConversationHostComponent);
