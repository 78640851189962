import API from './api';

const findByZipCode = async code => {
    const response = await API.get(`/county/findByZipCode?code=${code}`);
    if (response.status >= 400) {
        throw new Error('Failed to fetch zip code');
    }
    return response.data;
};

export default { findByZipCode };
