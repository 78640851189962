import API from './api';
import user from './user';
import drug from './drug';
import county from './county';
import conversation from './conversation';
import doctor from './doctor';
import pharmacy from './pharmacy';

export default {
    conversation,
    API,
    user,
    drug,
    county,
    doctor,
    pharmacy,
};
