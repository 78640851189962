import React, { Component } from 'react';
import { find as loFind, remove, get } from 'lodash';
import { isMobile } from 'react-device-detect';
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Modal,
    Radio,
    RadioGroup,
    TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { injectIntl, FormattedMessage } from 'react-intl';
import AsyncSelect from 'react-select/lib/Async';
import { connect } from 'react-redux';
import { components } from 'react-select';
import { getGeoState } from '../../../conversationState';
import services from '../../../services';
import CancelIcon from './cancel-icon.svg';
import EmButton from '../../shared/Button/Button';

class PrescriptionDropdown extends Component {
    constructor(props) {
        super(props);

        this.searchDelay = null;

        this.state = {
            addedItems: [],
            data: [],
            inputValue: '',
            location: '',
            value: null,
            isModalShown: false,
            addressChoices: null,
        };

        const footerProps = {
            hasNextButton: true,
            nextButtonText: props.intl.formatMessage({
                id: 'button.next',
            }),
            nextCallback: () => this.setOption(props.data),
        };
        props.triggerFooterUpdate(footerProps);
    }

    async componentDidMount() {
        if (
            !this.props ||
            !this.props.data ||
            !this.props.data.config ||
            !this.props.data.config.dataSourceType
        ) {
            throw new Error('Missing component configuration...');
        }

        await this.fetchCustomerData();
    }

    fetchCustomerData = async () => {
        let result;
        switch (this.props.data.config.dataSourceType) {
            case 'DRUGS':
                result = await services.user.fetchDrugs(this.props.accessToken);
                this.setState({
                    addedItems: result.map(drug => ({
                        label: drug.displayName,
                        value: drug.rxcui,
                        id: drug.id,
                    })),
                });
                break;
            case 'DOCTOR':
                result = await services.user.fetchDoctors(
                    this.props.accessToken
                );
                this.setState({
                    addedItems: result.map(doctor => ({
                        label: doctor.name,
                        value: doctor.npi,
                        id: doctor.id,
                        addresses: doctor.addresses,
                    })),
                });
                break;
            default:
                throw new Error('A valid dataSourceType needs to be set');
        }
    };

    setOption = async () => {
        const { goToNextQuestion, data } = this.props;
        const nextConversationItemId = get(
            data,
            'edges[0].nextConversationItemId'
        );
        const { conversationItemId } = this.props.data;
        try {
            await services.conversation.saveResponse({
                questionId: conversationItemId,
                answer: [
                    {
                        conversationItemId,
                        answer: JSON.stringify(this.state.addedItems),
                        secondaryAnswer: JSON.stringify(
                            this.state.addedItems.map(({ secondaryValue }) =>
                                secondaryValue ? secondaryValue : null
                            )
                        ),
                    },
                ],
            });
            goToNextQuestion({ id: nextConversationItemId });
        } catch (e) {
            console.log(e);
        }
    };

    loadOptions = (inputValue, callback) => {
        if (!inputValue || inputValue.length < 2) {
            /*
              This is a little annoying, but the goal is to prevent react-select's built-in
              "Loading..." indicator from appearing before we have enough chars to make a network call
            */
            callback([]);
            return;
        }
        const self = this;
        clearTimeout(self.searchDelay);

        self.searchDelay = setTimeout(async () => {
            let dataSource;
            let zipCode;
            switch (self.props.data.config.dataSourceType) {
                case 'DRUGS':
                    dataSource = await services.drug.find(inputValue);
                    break;
                case 'DOCTOR':
                    zipCode = await getGeoState();

                    dataSource = await services.doctor.findDoctor({
                        zipCode,
                        searchTerm: inputValue,
                        accessToken: this.props.accessToken,
                    });
                    break;
                default:
                    throw new Error('A valid dataSourceType needs to be set');
            }

            const filteredData = dataSource.data.map(row => {
                return {
                    label: row.displayName || row.label,
                    value: row.id,
                    secondaryValue: row.genericName ? row.genericName : null,
                    subLabel: row.subLabel,
                    ...(row.addresses && { addresses: row.addresses }),
                };
            });

            callback(filteredData);
        }, 500);
    };

    handleChange = value => {
        if (this.props.data.config.dataSourceType === 'DRUGS') {
            const allItems = this.state.addedItems;

            // find item, if exists don't add to array
            if (value && !loFind(allItems, { value: value.value })) {
                allItems.push(value);
            }

            this.setState({
                value: null,
                addedItems: allItems,
            });
        } else {
            this.setState({
                addressChoices: value.addresses,
                tempDoc: value,
                value: value,
            });
        }
    };

    pharmacySelected = (event, value) => {
        const doctorObject = { ...this.state.tempDoc, selectedAddress: value };

        const allItems = this.state.addedItems;

        // find item, if exists don't add to array
        // if (doctorObject && !loFind(allItems, { value: value.value })) {
        allItems.push(doctorObject);
        // }

        this.setState({
            value: null,
            addedItems: allItems,
            addressChoices: value.addresses,
            tempDoc: null,
        });
    };

    renderSelectedItems = ({ itemList = [], type }) => {
        if (type === 'DRUGS') {
            return (
                <ul className={'added-item-list'}>
                    {itemList.map((item, index) => {
                        return (
                            <li key={index}>
                                <img
                                    onClick={() =>
                                        this.removeAddedItem(item.value)
                                    }
                                    src={CancelIcon}
                                    alt="cancel icon"
                                />
                                {item.label}

                                <FormControl
                                    row
                                    component="fieldset"
                                    style={{
                                        marginTop: '-10px',
                                        marginLeft: 10,
                                    }}
                                >
                                    <FormLabel component="legend">
                                        # of refills per month
                                    </FormLabel>

                                    <TextField
                                        variant="outlined"
                                        inputProps={{
                                            style: {
                                                fontSize: 16,
                                                fontWeight: 800,
                                                padding: '8px',
                                            },
                                        }}
                                        onChange={({ target: { value } }) => {
                                            item.frequency = value;
                                        }}
                                    />
                                </FormControl>
                            </li>
                        );
                    })}
                </ul>
            );
        } else {
            return (
                <ul className={'added-item-list'}>
                    {itemList.map((item, index) => {
                        return (
                            <li key={index}>
                                <img
                                    onClick={() =>
                                        this.removeAddedItem(item.value)
                                    }
                                    src={CancelIcon}
                                    alt="cancel icon"
                                />
                                {`${item.label}, ${this.formatOptionLabel(
                                    item.selectedAddress
                                )}`}
                            </li>
                        );
                    })}
                </ul>
            );
        }
    };

    removeAddedItem = id => {
        let addedItemList = this.state.addedItems;
        remove(addedItemList, {
            value: id,
        });

        this.setState({
            addedItems: addedItemList,
        });
    };

    getNoOptionsMessageId = () => {
        switch (this.props.data.config.dataSourceType) {
            case 'DRUGS':
                return 'dropdown.DontRecognizePrescription';
            case 'DOCTOR':
                return 'dropdown.DontRecognizeDoctor';
            default:
                throw new Error('A valid dataSourceType needs to be set');
        }
    };

    getModalTriggerText = () => {
        switch (this.props.data.config.dataSourceType) {
            case 'DRUGS':
                return 'dropdown.FindYourPerscriptions';
            case 'DOCTOR':
                return 'dropdown.FindYourDoctor';
            default:
                throw new Error('A valid dataSourceType needs to be set');
        }
    };

    handleToggleModal = () => {
        this.setState({
            isModalShown: !this.state.isModalShown,
        });
    };

    noOptionsMessage = () => {
        return this.state.inputValue && this.state.inputValue.length >= 2 ? (
            <div style={{ textAlign: 'left' }}>
                <FormattedMessage id={this.getNoOptionsMessageId()} />
            </div>
        ) : null;
    };

    formatOptionLabel = option => {
        if (option) {
            const {
                street_line_1,
                street_line_2,
                city,
                state,
                zip_code,
            } = option;
            return `${street_line_1} ${
                street_line_2 ? ', ' + street_line_2 : `,`
            } ${city}, ${state} ${zip_code}`;
        }
    };

    render() {
        const {
            addedItems,
            isModalShown,
            inputValue,
            value,
            selectedAddress,
        } = this.state;
        return (
            <div style={{ width: '100%' }}>
                {this.renderSelectedItems({
                    itemList: addedItems,
                    type: this.props.data.config.dataSourceType,
                })}
                <Grid container>
                    <Grid md={6} style={{ width: '100%' }}>
                        <AsyncSelect
                            id="dropdown-select"
                            components={{ Option }}
                            className="autocomplete-dd fs-block"
                            data-hj-suppress
                            inputValue={inputValue}
                            loadOptions={this.loadOptions}
                            noOptionsMessage={this.noOptionsMessage}
                            onChange={this.handleChange}
                            onInputChange={inputValue => {
                                this.setState({ inputValue });
                            }}
                            placeholder={this.props.data.config.placeholder}
                            textFieldProps={{
                                label: '',
                                InputLabelProps: {
                                    shrink: false,
                                },
                                variant: 'outlined',
                            }}
                            value={value}
                        />
                    </Grid>
                    <Grid md={6} style={{ width: '100%' }}>
                        {this.state.addressChoices && (
                            <Autocomplete
                                id="combo-box-demo"
                                options={this.state.addressChoices}
                                getOptionLabel={option =>
                                    this.formatOptionLabel(option)
                                }
                                onChange={this.pharmacySelected}
                                value={selectedAddress}
                                style={{ marginTop: '-5px' }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label="Select doctor's office location you visit"
                                    />
                                )}
                            />
                        )}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const Option = props => {
    return (
        <components.Option {...props}>
            <div>{props.data.label}</div>
            <div style={{ fontSize: 12 }}>{props.data.subLabel}</div>
        </components.Option>
    );
};

const mapStateToProps = state => ({
    accessToken: state.accessToken,
});

export default connect(mapStateToProps, null)(injectIntl(PrescriptionDropdown));
