import axios from 'axios';
import uuidv4 from 'uuid/v4';

let apiUrl = 'http://localhost:3002';
if (process.env.REACT_APP_EM_ENV === 'prod') {
    apiUrl = 'https://api.entermedicare.com';
} else if (
    process.env.REACT_APP_EM_ENV === 'dev' &&
    !process.env.REACT_APP_IS_TRAVIS
) {
    apiUrl = 'https://api.entermedicare-dev.com';
}

const API = axios.create({
    baseURL: apiUrl,
    headers: {
        Accept: 'application/json',
    },
});

API.interceptors.request.use(
    function(config) {
        config.headers['X-Entermedicare-Request-Id'] = uuidv4();
        if (window.DD_LOGS && window.DD_LOGS.logger) {
            window.DD_LOGS.logger.info('sending request. data:', config);
        }
        return config;
    },
    function(error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

export default API;
