import React from 'react';

const Check = () => {
    return (
        <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <rect width="30" height="30" rx="3" fill="#3EC28F"></rect>
                <svg
                    width="30"
                    height="30"
                    viewBox="-7 -7 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M16.2213 2.29309L15.6603 1.63952C15.4616 1.42556 15.2257 1.25583 14.966 1.14002C14.7063 1.02421 14.4279 0.9646 14.1467 0.9646C13.8656 0.9646 13.5872 1.02421 13.3275 1.14002C13.0678 1.25583 12.8318 1.42556 12.6332 1.63952L6.73095 7.98665L5.12974 6.26476C4.93109 6.0508 4.69516 5.88107 4.43545 5.76526C4.17574 5.64945 3.89735 5.58984 3.6162 5.58984C3.33504 5.58984 3.05665 5.64945 2.79694 5.76526C2.53723 5.88107 2.3013 6.0508 2.10265 6.26476L1.52996 6.88062C1.331 7.09424 1.17316 7.34796 1.06547 7.62724C0.957774 7.90653 0.902344 8.20591 0.902344 8.50825C0.902344 8.8106 0.957774 9.10998 1.06547 9.38926C1.17316 9.66855 1.331 9.92225 1.52996 10.1359L4.63886 13.4791L5.21156 14.095C5.41021 14.3089 5.64614 14.4787 5.90585 14.5945C6.16556 14.7103 6.44395 14.7699 6.7251 14.7699C7.00625 14.7699 7.28465 14.7103 7.54436 14.5945C7.80407 14.4787 8.04 14.3089 8.23865 14.095L8.81134 13.4791L16.2213 5.51064C16.6125 5.08081 16.8315 4.5033 16.8315 3.90186C16.8315 3.30043 16.6125 2.72292 16.2213 2.29309Z"
                        fill="url(#paint0_linear)"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear"
                            x1="8.88147"
                            y1="12.1846"
                            x2="8.88147"
                            y2="-29.7191"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white" />
                            <stop offset="0.41" stopColor="#CCE2E6" />
                            <stop offset="1" stopColor="#8FA1BB" />
                        </linearGradient>
                    </defs>
                </svg>
            </g>
        </svg>
    );
};

Check.propTypes = {};
Check.defaultProps = {};

export default Check;
