import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const Input = React.forwardRef(
    (
        {
            callback,
            placeholder,
            type,
            isDisabled,
            customClass,
            textPosition,
            value,
            name,
            focusOnLoad,
            ...rest
        },
        ref
    ) => {
        return (
            <input
                ref={ref}
                value={value}
                type={type}
                onChange={callback}
                name={name}
                placeholder={placeholder}
                onFocus={e =>
                    focusOnLoad
                        ? (e.target.placeholder = placeholder)
                        : (e.target.placeholder = '')
                }
                onBlurCapture={e => (e.target.placeholder = placeholder)}
                disabled={isDisabled}
                className={`${customClass ||
                    ''} input ${textPosition} fs-block`}
                data-hj-suppress
                {...rest}
                autoFocus={focusOnLoad ? true : false}
            />
        );
    }
);

Input.propTypes = {
    callback: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    isDisabled: PropTypes.bool,
    customClass: PropTypes.string,
    textPosition: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.any,
    value: PropTypes.string,
};
Input.defaultProps = {
    type: 'text',
};
Input.displayName = 'Input';

export default Input;
