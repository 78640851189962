import React, { Component, createRef } from 'react';
import { withRouter } from 'react-router-dom';
import { get, find, remove } from 'lodash';
import services from '../../../services';
import {
    TextField,
    InputLabel,
    Input,
    Select,
    MenuItem,
    FormControl,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DatePicker } from '@material-ui/pickers';
import MaskedInput from 'react-text-mask';
import { states, incomeOptions } from '../../../constants';
import AsyncSelect from 'react-select/lib/Async';
import CancelIcon from './cancel-icon.svg';
import { components } from 'react-select';
import './../index.scss';
import moment from 'moment';

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[
                '(',
                /[1-9]/,
                /\d/,
                /\d/,
                ')',
                ' ',
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
            ]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}

const Option = props => {
    return (
        <components.Option {...props}>
            <div>{props.data.label}</div>
            <div style={{ fontSize: 12 }}>{props.data.subLabel}</div>
        </components.Option>
    );
};

class BrokerConversation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hasAorB: false,
            state: states[0],
            dateOfBirth: null,
            drugArray: [],
            addedDrugs: [],
            drugInputValue: '',
            drugValue: null,
            doctorArray: [],
            addedDoctors: [],
            doctorInputValue: '',
            doctorValue: null,
            gender: 'MALE',
            incomeSelection: incomeOptions[0],
            spouseEligible: false,
        };

        this.firstName = createRef();
        this.lastName = createRef();

        this.dateOfBirth = createRef();
        this.phone = createRef();
        this.address1 = createRef();
        this.address2 = createRef();
        this.city = createRef();
        this.zip = createRef();
        this.monthlyPremium = createRef();
        this.prescriptionPremium = createRef();

        const footerProps = {
            hasNextButton: true,
            nextCallback: this.saveSelection,
            nextButtonText: 'Next',
        };
        props.triggerFooterUpdate(footerProps);
    }
    componentDidMount = () => {
        if (this.props.hideFaq) {
            this.props.hideFaq(false);
        }
    };

    handleSubmit(event) {
        alert('A name was submitted: ' + this.input.current.value);
        event.preventDefault();
    }

    handleBirthday = event => this.setState({ dateOfBirth: event.format() });
    handlePhoneChange = ({ target: { value } }) =>
        this.setState({ phone: value });
    handleStateChange = ({ target: { value } }) =>
        this.setState({ state: value });

    saveSelection = async () => {
        const { goToNextQuestion, data } = this.props;
        const nextConvo = get(data, 'edges[0].nextConversationItemId');

        try {
            const results = {
                answers: [
                    {
                        brokerFormValues: {
                            phone: this.state.phone,
                            monthlyPremium: this.monthlyPremium.value,
                            prescriptionPremium: this.prescriptionPremium.value,
                        },
                    },

                    {
                        decisionType: 'TAX_FILING_TYPE',
                        responseType: this.state.incomeSelection.value,
                        answer: this.state.incomeSelection.filingType,
                        isOption: false,
                        conversationItemId:
                            process.env.REACT_APP_EM_ENV !== 'prod'
                                ? '6LTrg8BELbpsffC2iT4o6A'
                                : null,
                    },

                    {
                        decisionType: 'INCOME',
                        responseType: this.state.incomeSelection.value,
                        answer: this.state.incomeSelection.value,
                        isOption: true,
                        conversationItemId:
                            process.env.REACT_APP_EM_ENV !== 'prod'
                                ? this.state.incomeSelection.conversationItemId
                                : null,
                        conversationItemOptionId:
                            process.env.REACT_APP_EM_ENV !== 'prod'
                                ? this.state.incomeSelection
                                      .conversationItemOptionId
                                : null,
                    },

                    {
                        decisionType: 'FIRST_NAME',
                        responseType: '',
                        answer: this.firstName.value,
                        isOption: false,
                        conversationItemId:
                            process.env.REACT_APP_EM_ENV !== 'prod'
                                ? '3KBoxhxV0nERnVzmd6Bzb7'
                                : null,
                    },
                    {
                        decisionType: 'LAST_NAME',
                        responseType: '',
                        answer: this.lastName.value,
                        isOption: false,
                        conversationItemId:
                            process.env.REACT_APP_EM_ENV !== 'prod'
                                ? '25nTwlQdTMzoGckSzD1I20'
                                : null,
                    },
                    {
                        decisionType: 'BIRTH_DATE',
                        responseType: '',
                        answer: moment(this.state.dateOfBirth).format(
                            'MM-DD-YYYY'
                        ),
                        isOption: false,
                        conversationItemId:
                            process.env.REACT_APP_EM_ENV !== 'prod'
                                ? '2w6M6JXK2SPt1DU8ThKCmB'
                                : null,
                    },
                    {
                        decisionType: 'ZIP_CODE',
                        responseType: '',
                        answer: this.zip.value,
                        isOption: false,
                        conversationItemId:
                            process.env.REACT_APP_EM_ENV !== 'prod'
                                ? '7cXx18zoBoQKxEkGCbfSQD'
                                : null,
                    },
                    {
                        decisionType: 'GENDER',
                        responseType: this.state.gender,
                        answer: '',
                        isOption: true,
                        conversationItemId:
                            process.env.REACT_APP_EM_ENV !== 'prod'
                                ? '7IQmwId0nMErR6FkJhHhls'
                                : null,
                    },

                    {
                        decisionType: 'DRUG',
                        conversationItemId:
                            process.env.REACT_APP_EM_ENV !== 'prod'
                                ? '4tMTi09gZ49X4BSmhswjq5'
                                : null,
                        answer: JSON.stringify(this.state.drugArray),
                    },
                    {
                        decisionType: 'DOCTOR',
                        conversationItemId:
                            process.env.REACT_APP_EM_ENV !== 'prod'
                                ? '5S2sLk7ZvpK2fvXTx7WglA'
                                : null,
                        answer: JSON.stringify(this.state.doctorArray),
                    },
                ],
            };
            // await services.conversation.saveResponse(results);
            window.localStorage.setItem(
                'conversationState',
                JSON.stringify(results)
            );
            goToNextQuestion({ id: nextConvo });
        } catch (e) {
            console.log(e);
        }
    };

    fetchDrugs = async () => {
        const result = services.user.fetchDrugs(this.props.accessToken);
        this.setState({
            drugArray: result.map(drug => ({
                label: drug.displayName,
                value: drug.rxcui,
                id: drug.id,
            })),
        });
    };

    fetchDoctors = async () => {
        const result = await services.user.fetchDoctors(this.props.accessToken);
        this.setState({
            addedItems: result.map(doctor => ({
                label: doctor.name,
                value: doctor.npi,
                id: doctor.id,
            })),
        });
    };

    handleKeyPress = e => {
        if (e.key === 'Enter' && this.state.text) {
            this.saveSelection();
        }
    };
    handleChange = newVal => {
        this.setState({ text: newVal });
    };

    loadDrugOptions = (inputValue, callback) => {
        if (!inputValue || inputValue.length < 2) {
            /*
              This is a little annoying, but the goal is to prevent react-select's built-in
              "Loading..." indicator from appearing before we have enough chars to make a network call
            */
            callback([]);
            return;
        }
        const self = this;
        clearTimeout(self.searchDelay);

        self.searchDelay = setTimeout(async () => {
            let dataSource;
            dataSource = await services.drug.find(inputValue);
            const filteredData = dataSource.data.map(row => {
                return {
                    label: row.displayName || row.label,
                    value: row.id,
                    secondaryValue: row.genericName ? row.genericName : null,
                    subLabel: row.subLabel,
                };
            });

            callback(filteredData);
        }, 500);
    };

    handleDrugChange = value => {
        const allItems = this.state.drugArray;

        // find item, if exists don't add to array
        if (value && !find(allItems, { value: value.value })) {
            allItems.push(value);
        }

        this.setState({
            value: null,
            drugArray: allItems,
        });
    };

    renderSelectedItems = itemList => {
        return (
            <ul className={'added-item-list'}>
                {itemList.map((item, index) => {
                    return (
                        <li key={index}>
                            <img
                                onClick={() => this.removeAddedItem(item.value)}
                                src={CancelIcon}
                                alt="cancel icon"
                            />
                            {item.label}
                        </li>
                    );
                })}
            </ul>
        );
    };

    removeAddedDrug = id => {
        let addedItemList = this.state.drugArray;
        remove(addedItemList, {
            value: id,
        });

        this.setState({
            addedItems: addedItemList,
        });
    };

    loadDoctorOptions = (inputValue, callback) => {
        if (!inputValue || inputValue.length < 2) {
            /*
              This is a little annoying, but the goal is to prevent react-select's built-in
              "Loading..." indicator from appearing before we have enough chars to make a network call
            */
            callback([]);
            return;
        }
        const self = this;
        clearTimeout(self.searchDelay);

        self.searchDelay = setTimeout(async () => {
            let dataSource;
            const zipCode = this.zip.value;
            if (!zipCode) alert('please fill out zipcode first');

            dataSource = await services.doctor.findDoctor({
                zipCode,
                searchTerm: inputValue,
                accessToken: this.props.accessToken,
            });
            const filteredData = dataSource.data.map(row => {
                return {
                    label: row.displayName || row.label,
                    value: row.id,
                    secondaryValue: row.genericName ? row.genericName : null,
                    subLabel: row.subLabel,
                };
            });

            callback(filteredData);
        }, 500);
    };

    handleDoctorChange = value => {
        const allItems = this.state.doctorArray;

        // find item, if exists don't add to array
        if (value && !find(allItems, { value: value.value })) {
            allItems.push(value);
        }

        this.setState({
            value: null,
            doctorArray: allItems,
        });
    };

    noOptionsMessage = () => {
        return this.state.inputValue && this.state.inputValue.length >= 2 ? (
            <div style={{ textAlign: 'left' }}>
                {'No Options Yet. Continue Typing'}
            </div>
        ) : null;
    };

    render() {
        const brokerTextfield = {
            marginBottom: '20px',
            fontSize: 32,
        };
        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <form
                    onSubmit={this.handleSubmit}
                    style={{ paddingBottom: 200 }}
                >
                    <TextField
                        name="firstName"
                        style={brokerTextfield}
                        type="text"
                        fullWidth
                        variant="filled"
                        inputRef={e => (this.firstName = e)}
                        label="First Name"
                    />
                    <TextField
                        style={brokerTextfield}
                        type="text"
                        fullWidth
                        variant="filled"
                        inputRef={e => (this.lastName = e)}
                        label="Last Name"
                    />

                    <FormControl variant="filled" fullWidth>
                        <InputLabel id="gender">Gender</InputLabel>
                        <Select
                            style={brokerTextfield}
                            labelId="gender"
                            id="gender"
                            value={this.gender}
                            onChange={({ target: { value } }) => {
                                this.setState({ gender: value });
                            }}
                            fullWidth
                        >
                            {['MALE', 'FEMALE'].map((x, i) => (
                                <MenuItem value={x} key={i}>
                                    {' '}
                                    {x}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <InputLabel> Birthday </InputLabel>
                    <DatePicker
                        name="birthDay"
                        openTo="year"
                        format="DD/MM/yyyy"
                        emptyLabel="mm/dd/yyyy"
                        views={['year', 'month', 'date']}
                        disableFuture={true}
                        style={brokerTextfield}
                        value={this.state.dateOfBirth}
                        fullWidth
                        onChange={this.handleBirthday}
                    />

                    <FormControl variant="filled" fullWidth>
                        <InputLabel id="Spouse-Eligible">
                            Do you have a spouse or loved one covered under your
                            plan?
                        </InputLabel>
                        <Select
                            style={brokerTextfield}
                            labelId="Spouse-Eligible"
                            id="Spouse-Eligible"
                            value={this.spouseEligible}
                            renderValue={value => value.label}
                            onChange={({ target: { value } }) => {
                                this.setState({ spouseEligible: value });
                            }}
                            fullWidth
                            inputProps={{ style: { fontSize: 32 } }}
                        >
                            {[
                                { label: 'YES', value: true },
                                { label: 'NO', value: false },
                            ].map((x, i) => (
                                <MenuItem value={x} key={i}>
                                    {x.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <InputLabel htmlFor="formatted-text-mask-input">
                        Phone Number
                    </InputLabel>
                    <Input
                        style={brokerTextfield}
                        value={this.state.phone}
                        onChange={this.handlePhoneChange}
                        name="textmask"
                        id="formatted-text-mask-input"
                        inputComponent={TextMaskCustom}
                    />
                    <TextField
                        style={brokerTextfield}
                        type="text"
                        fullWidth
                        variant="filled"
                        ref={this.address1}
                        label="Address1"
                    />
                    <TextField
                        style={brokerTextfield}
                        type="text"
                        fullWidth
                        variant="filled"
                        ref={this.address2}
                        label="Address2"
                    />
                    <TextField
                        style={brokerTextfield}
                        type="text"
                        fullWidth
                        variant="filled"
                        ref={this.city}
                        label="city"
                    />

                    <FormControl variant="filled" fullWidth>
                        <InputLabel id="demo-simple-select-filled-label">
                            State
                        </InputLabel>
                        <Select
                            style={brokerTextfield}
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            value={this.state.name}
                            onChange={x => this.handleStateChange(x)}
                            fullWidth
                        >
                            {states.map((x, i) => (
                                <MenuItem value={x} key={i}>
                                    {' '}
                                    {x.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        style={brokerTextfield}
                        type="text"
                        fullWidth
                        variant="filled"
                        inputRef={e => (this.zip = e)}
                        label="zip"
                    />

                    <FormControl variant="filled" fullWidth>
                        <InputLabel id="AorB-label">
                            Have you purchased Medicare parts A or B?{' '}
                        </InputLabel>
                        <Select
                            style={brokerTextfield}
                            labelId="AorB-label"
                            id="aorb-select"
                            value={this.incomeSelection}
                            renderValue={value => value.label}
                            onChange={({ target: { value } }) => {
                                this.setState({ hasAorB: value });
                            }}
                            fullWidth
                            inputProps={{ style: { fontSize: 32 } }}
                        >
                            {[
                                { label: 'Yes', value: true },
                                { label: 'No', value: false },
                            ].map((x, i) => (
                                <MenuItem value={x} key={i}>
                                    {x.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <TextField
                        style={brokerTextfield}
                        type="number"
                        fullWidth
                        variant="filled"
                        ref={e => (this.monthlyPremium = e)}
                        label="monthly premium"
                    />
                    <TextField
                        style={brokerTextfield}
                        type="number"
                        fullWidth
                        variant="filled"
                        ref={e => (this.prescriptionPremium = e)}
                        label="prescription premium"
                    />

                    <FormControl variant="filled" fullWidth>
                        <InputLabel id="income-selection">
                            Tax Bracket (This affects medicare part B pricing)
                        </InputLabel>
                        <Select
                            style={brokerTextfield}
                            labelId="income-selection"
                            id="income-selection"
                            value={this.incomeSelection}
                            renderValue={value => value.label}
                            onChange={({ target: { value } }) => {
                                this.setState({ incomeSelection: value });
                            }}
                            fullWidth
                            inputProps={{ style: { fontSize: 32 } }}
                        >
                            {incomeOptions.map((x, i) => (
                                <MenuItem value={x} key={i}>
                                    {x.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div style={{ paddingBottom: 20 }}>
                        <InputLabel id="Drug Search">Prescriptions</InputLabel>
                        {this.renderSelectedItems(this.state.drugArray)}
                        <AsyncSelect
                            style={brokerTextfield}
                            id="dropdown-select"
                            components={{ Option }}
                            className="autocomplete-dd fs-block"
                            data-hj-suppress
                            inputValue={this.state.drugInputValue}
                            loadOptions={this.loadDrugOptions}
                            noOptionsMessage={this.noOptionsMessage}
                            onChange={this.handleDrugChange}
                            onInputChange={drugInputValue =>
                                this.setState({ drugInputValue })
                            }
                            placeholder={'Ex: Albuterol sulfate'}
                            textFieldProps={{
                                label: '',
                                InputLabelProps: {
                                    shrink: false,
                                },
                            }}
                            value={this.state.drugValue}
                        />
                    </div>
                    <div style={{ paddingBottom: 20 }}>
                        <InputLabel id="Doctor Search">
                            Doctor Search
                        </InputLabel>
                        {this.renderSelectedItems(this.state.doctorArray)}
                        <AsyncSelect
                            style={brokerTextfield}
                            id="dropdown-select"
                            components={{ Option }}
                            className="autocomplete-dd fs-block"
                            data-hj-suppress
                            inputValue={this.state.doctorInputValue}
                            loadOptions={this.loadDoctorOptions}
                            noOptionsMessage={this.noOptionsMessage}
                            onChange={this.handleDoctorChange}
                            onInputChange={doctorInputValue =>
                                this.setState({ doctorInputValue })
                            }
                            placeholder={'Ex: John Smith'}
                            textFieldProps={{
                                label: '',
                                InputLabelProps: {
                                    shrink: false,
                                },
                            }}
                            value={this.state.doctorValue}
                        />
                    </div>
                </form>
            </MuiPickersUtilsProvider>
        );
    }
}

export default withRouter(BrokerConversation);
