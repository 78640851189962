import React from 'react';
import PropTypes from 'prop-types';
import BackButton from '../BackButton/BackButton';
import EmButton from '../../shared/EmButton.js';
import { injectIntl } from 'react-intl';
import './index.scss';
import DisclaimerFooter from '../../DisclaimerFooter';

const fsUnmask = 'fs-unmask';

const Footer = ({
    backButtonCallback,
    backButtonHidden,
    hasNextButton,
    intl,
    nextButtonText,
    nextCallback,
    triggerAnimation,
    isFirstQuestion,
    skipButton,
    skipCallback,
    bookFirst,
    bookNowCallback,
    bookLaterCallback,
    skipRestAndFinish,
    finishConversationCallback,
}) => {
    if (bookFirst) {
        return (
            <div className="footer" style={{ justifyContent: 'flex-end' }}>
                <div>
                    <EmButton
                        text={'See Plans'}
                        onClick={bookLaterCallback}
                        style={{ fontSize: 20 }}
                        className={fsUnmask}
                    />
                    <EmButton
                        text={'Book Now'}
                        onClick={bookNowCallback}
                        style={{ fontSize: 20 }}
                        className={fsUnmask}
                    />
                </div>
            </div>
        );
    } else {
        return (
            <div className="footerContainer">
                <div className="footer">
                    <div className="left-side">
                        {!backButtonHidden && (
                            <BackButton
                                isFirstQuestion={isFirstQuestion}
                                triggerAnimation={triggerAnimation}
                                callback={backButtonCallback}
                            />
                        )}
                        {skipRestAndFinish === true && (
                            <EmButton
                                text={'Skip Onboarding'}
                                onClick={finishConversationCallback}
                                style={{ fontSize: 16 }}
                                className={fsUnmask}
                            />
                        )}
                    </div>
                    <div className="right-side">
                        {hasNextButton && (
                            <EmButton
                                text={
                                    typeof nextButtonText === 'object'
                                        ? intl.formatMessage(
                                              nextButtonText.props
                                          )
                                        : nextButtonText
                                }
                                onClick={nextCallback}
                                style={{ fontSize: 20 }}
                                className={fsUnmask}
                            />
                        )}
                        {skipButton && (
                            <EmButton
                                text={'Skip'}
                                onClick={skipCallback}
                                style={{ fontSize: 20 }}
                                className={fsUnmask + ' skipButton'}
                            />
                        )}
                    </div>
                </div>
                <DisclaimerFooter />
            </div>
        );
    }
};

Footer.propTypes = {
    isFirstQuestion: PropTypes.bool,
    backButtonCallback: PropTypes.func,
    backButtonHidden: PropTypes.bool,
    hasNextButton: PropTypes.bool.isRequired,
    nextCallback: PropTypes.func,
    nextButtonText: PropTypes.oneOfType([
        PropTypes.object, // object b/c it could be a react-intl FormattedMessage
        PropTypes.string,
    ]),
};

export default injectIntl(Footer);
