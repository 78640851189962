import React, { Component } from 'react';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { withRouter } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import Text from '../Text';

const CurrencyMaskInput = props => {
    let { inputRef, ...others } = props;

    return (
        <MaskedInput
            {...others}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={createNumberMask({ allowDecimal: true })}
            placeholder={'$0.00'}
            showMask={false}
        />
    );
};

class Currency extends Component {
    componentDidMount() {
        console.log('decisionType', this.props.data.decisionType);
        console.log('prefill', this.props.prefillData);
    }
    render() {
        return <Text mask={CurrencyMaskInput} {...this.props} />;
    }
}

export default withRouter(Currency);
