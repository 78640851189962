import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    withRouter,
} from 'react-router-dom';
import { injectIntl } from 'react-intl';
import queryString from 'query-string';
import { get } from 'lodash';
import * as Sentry from '@sentry/react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import EMErrorBoundary from '../src/components/shared/EMErrorBoundary';
import { connect } from 'react-redux';

import { setBrokerId, setAccessToken, setReferenceCode } from './actions';
import Conversation from './components/Conversation';
import {
    ErrorPage,
    FinalPage,
    BestPlanPage,
    EnrollmentPrepPage,
} from './pages';
import './App.css';
import Navbar from './components/shared/NavBar/NavBar';
import { setTrackingCookies } from './utils';
import {
    getConversationBySubdomain,
    getConversationByUrlParam,
} from './services/conversation';
import { entermedicareTheme } from './constants';

// These are temporary until we implement internationalization
import { errorPageParagraphs } from './components/utils/error-page-text';
import { fetchProfile } from './services/user';

class App extends Component {
    constructor(props) {
        super(props);

        setTrackingCookies();

        this.state = {
            titles: [],
            conversation: {},
            hideFaqButton: true, //hide until faq copy complete
        };
    }

    async componentDidMount() {
        let conversation = await getConversationByUrlParam();
        if (!conversation) {
            // Older method of retrieving conversation
            conversation = await getConversationBySubdomain();
        }
        if (get(conversation, 'firstItemId')) {
            const { accessToken, brokerId, referenceCode } = queryString.parse(
                this.props.location.search
            );

            const profile = await fetchProfile(accessToken);
            if (localStorage.getItem('email') !== profile.email) {
                localStorage.clear();
                localStorage.setItem('email', profile.email);
            }

            const titles = () => {
                if (
                    Object.prototype.hasOwnProperty.call(conversation, 'meta')
                ) {
                    const parsedMeta = JSON.parse(conversation.meta);
                    if (
                        parsedMeta &&
                        Object.prototype.hasOwnProperty.call(
                            parsedMeta,
                            'progressBarTitles'
                        )
                    )
                        return parsedMeta.progressBarTitles;
                } else {
                    return [];
                }
            };
            if (brokerId) {
                this.props.setBroker(brokerId);
            }
            if (accessToken) {
                this.props.setAuth(accessToken);
            }
            if (referenceCode) {
                this.props.setRefCode(referenceCode);
            }
            this.setState({
                titles: titles(),
                conversation,
            });
        } else {
            this.props.history.push('/404');
        }
    }

    hideFaq = value => {
        if (this.state.hideFaqButton !== value) {
            this.setState({
                hideFaqButton: value,
            });
        }
    };

    redirectToFirstQuestion = firstItemId => {
        localStorage.clear();
        const { accessToken, conversationId } = queryString.parse(
            this.props.location.search
        );
        if (firstItemId) {
            if (conversationId) {
                if (window.hj) window.hj('event', 'conversation-started');
                window.location.href = `/conversation/question/${firstItemId}?conversationId=${conversationId}&accessToken=${accessToken}&backButtonHidden=1`;
            } else {
                window.location.href = `/conversation/question/${firstItemId}?accessToken=${accessToken}&backButtonHidden=1`;
            }
        }
    };

    render() {
        return (
            <EMErrorBoundary sentry={Sentry}>
                <Navbar
                    titles={this.state.titles}
                    hideFaqButton={this.state.hideFaqButton}
                />
                <Switch>
                    <Route
                        exact
                        path="/"
                        render={() =>
                            this.redirectToFirstQuestion(
                                this.state.conversation.firstItemId
                            )
                        }
                    />
                    <Route
                        path="/conversation"
                        render={props => (
                            <Conversation
                                {...props}
                                conversation={this.state.conversation}
                                progressBarTitles={this.state.titles}
                                hideFaq={this.hideFaq}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/final"
                        component={() => (
                            <FinalPage
                                title={this.props.intl.formatMessage({
                                    id: 'finalPage.title',
                                })}
                                paragraph={this.props.intl.formatMessage({
                                    id: 'finalPage.paragraph',
                                })}
                                yellowParagraph={this.props.intl.formatMessage({
                                    id: 'finalPage.yellowParagraph',
                                })}
                                buttonText={this.props.intl.formatMessage({
                                    id: 'finalPage.buttonText',
                                })}
                                buttonCallback={() => {}}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/plan-recommendation"
                        component={props => (
                            <BestPlanPage
                                {...props}
                                nextButtonText="Begin Signup"
                                nextButtonCallback={() =>
                                    props.history.push('/enrollment-prep')
                                }
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/enrollment-prep"
                        component={props => (
                            <EnrollmentPrepPage
                                {...props}
                                nextButtonText="I've got it"
                                nextButtonCallback={() => {}}
                            />
                        )}
                    />
                    <Route
                        component={() => (
                            <ErrorPage paragraphs={errorPageParagraphs} />
                        )}
                    />
                </Switch>
            </EMErrorBoundary>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    setBroker: val => dispatch(setBrokerId(val)),
    setAuth: val => dispatch(setAccessToken(val)),
    setRefCode: val => dispatch(setReferenceCode(val)),
});
const AppWithRouter = withRouter(
    injectIntl(connect(null, mapDispatchToProps)(App))
);

class AppWrapper extends React.Component {
    render() {
        return (
            <MuiThemeProvider theme={entermedicareTheme}>
                <Router>
                    <AppWithRouter />
                </Router>
            </MuiThemeProvider>
        );
    }
}

export default AppWrapper;
