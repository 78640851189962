import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import Text from '../Text';

const PhoneNumberMaskInput = props => {
    let { inputRef, ...others } = props;
    return (
        <MaskedInput
            {...others}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[
                '(',
                /[1-9]/,
                /\d/,
                /\d/,
                ')',
                ' ',
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
            ]}
            placeholderChar={'\u2000'}
            showMask={false}
        />
    );
};

class Phone extends Component {
    render() {
        return <Text mask={PhoneNumberMaskInput} fsblock {...this.props} />;
    }
}

export default withRouter(Phone);
