import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import queryString from 'query-string';

import SimpleLoading from '../../components/shared/SimpleLoading/SimpleLoading';
import Footer from '../../components/shared/Footer/Footer';

import { findPlans } from '../../services/plan';

class BestPlanPage extends Component {
    state = {
        isLoading: true,
        bestPlanData: {},
    };

    componentDidMount() {
        const { planToken } = queryString.parse(this.props.location.search);

        findPlans(planToken).then(res => {
            this.setState({ bestPlanData: res[0] });
        });
    }

    render() {
        const {
            intl,
            nextButtonText,
            nextButtonCallback,
            saveButtonCallback,
            saveButtonText,
        } = this.props;

        const { bestPlanData } = this.state;

        return this.state.isLoading ? (
            <SimpleLoading
                animationCompleteCallback={() =>
                    this.setState({ isLoading: false })
                }
            />
        ) : (
            <div>
                <ContentContainer>
                    <Title>
                        {intl.formatMessage({
                            id: 'bestPlanPage.title',
                        })}
                    </Title>
                    <SubTitle>
                        <FormattedMessage
                            id="bestPlanPage.subTitle"
                            values={{ name: bestPlanData.name }}
                        />
                    </SubTitle>
                    <PlanInfoContainer>
                        <ListContainer>
                            {bestPlanData.items.map(item => (
                                <InfoItemContainer key={item}>
                                    <ItemTitle>{item.label}</ItemTitle>
                                    <ItemValue>{item.value}</ItemValue>
                                </InfoItemContainer>
                            ))}
                        </ListContainer>
                    </PlanInfoContainer>
                    <AfterTitle>
                        {intl.formatMessage({
                            id: 'bestPlanPage.afterTitle',
                        })}
                    </AfterTitle>
                    <OtherOptions>
                        {intl.formatMessage({
                            id: 'bestPlanPage.otherOptions',
                        })}
                    </OtherOptions>
                </ContentContainer>

                <Footer
                    hasNextButton
                    saveButtonText={saveButtonText}
                    saveButtonCallback={saveButtonCallback}
                    nextButtonText={nextButtonText}
                    nextCallback={nextButtonCallback}
                />
            </div>
        );
    }
}

BestPlanPage.propTypes = {
    saveButtonText: PropTypes.string,
    saveButtonCallback: PropTypes.func,
    nextButtonText: PropTypes.string,
    nextButtonCallback: PropTypes.func,
};

const ContentContainer = styled.div`
    margin: 45px 12.5% 150px 16.667%;
`;

export const Title = styled.h1`
    font-family: 'Le Monde Livre Pro';
    font-size: 48px;
    line-height: 66px;
    color: #041f1e;
    font-weight: bold;
`;

const SubTitle = styled.h3`
    font-family: 'Source Sans Pro';
    font-size: 32px;
    line-height: 48px;
    color: #041f1e;
    font-weight: normal;
`;

const PlanInfoContainer = styled.div`
    border: 1px solid #ffd757;
    border-radius: 8px;
`;

const ListContainer = styled.ul`
    display: flex;
    justify-content: left;
    align-items: left;
    flex-direction: column;
    overflow-x: hidden;
    list-style: none;
    margin: 0 36px 36px 0;
`;

export const InfoItemContainer = styled.li`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    margin-top: 36px;

    &::before {
        border-bottom: 1px dashed #000;
        content: '';
        flex-grow: 1;
        height: 1em;
        order: 2;
        margin: 0 3px;
    }
`;

const ItemTitle = styled.span`
    font-family: 'Source Sans Pro';
    font-size: 32px;
    line-height: 48px;
    color: #041f1e;
    font-weight: normal;
    margin-right: 15px;
`;

const ItemValue = styled.span`
    font-family: 'Source Sans Pro';
    font-size: 32px;
    line-height: 48px;
    color: #041f1e;
    font-weight: normal;
    margin-left: 30px;
    order: 3;
`;

const AfterTitle = styled.h3`
    font-family: 'Source Sans Pro';
    font-size: 24px;
    line-height: 32px;
    color: #041f1e;
    font-weight: normal;
    margin-top: 30px;
`;

const OtherOptions = styled.h3`
    font-family: 'Source Sans Pro';
    font-size: 20px;
    line-height: 30px;
    color: #041f1e;
    font-weight: normal;
    margin-top: 100px;
`;

export default withRouter(injectIntl(BestPlanPage));
