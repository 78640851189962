import React, { Component } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Grid } from '@material-ui/core';
import Card from '../../shared/Card/Card';
import { get } from 'lodash';

class ConversationHostComponent extends Component {
    constructor(props) {
        super(props);
        const footerProps = {
            hasNextButton: true,
            skipButton: props.data.skip,
            nextButtonText: props.data.buttonLabel || 'Next',
            nextCallback: () => {
                const nextConvo = get(
                    this.props,
                    'data.edges[0].nextConversationItemId'
                );
                const id = this.props.data.edges.length ? nextConvo : null;
                return props.goToNextQuestion({
                    id,
                });
            },
            skipCallback: () => {
                return props.skipThisQuestion({
                    id: props.data.skip,
                });
            },
        };
        props.triggerFooterUpdate(footerProps);
    }

    componentDidMount() {
        if (this.props.data.redirectUrl) {
            this.props.goToNextQuestion({
                redirectUrl: this.props.data.redirectUrl,
            });
        }
    }

    maybeRenderIcon = () => {
        const iconUrl = this.props.data.iconUrl;
        return iconUrl ? <IconImg src={iconUrl} alt="icon" /> : null;
    };

    maybeRenderIllustration = () => {
        const illustrationUrl = this.props.data.illustrationUrl;
        return illustrationUrl ? (
            <IllustrationImg
                src={illustrationUrl.fields.file.url}
                alt="illustration"
            />
        ) : null;
    };

    renderSingleOption() {
        const { description, name } = this.props.data;

        if (this.props.data.conversationItemId == '608LqYjVNuoDcv00fQybss' || this.props.data.conversationItemId == '70rlJ8wIkXL5fAc2c2LxKK') {
            return (
                <Grid container>
                    <Grid item sm={6}>
                        {this.maybeRenderIcon()}
                        <Header>{name}</Header>
                        <h4>
                            {' '}
                            {`Since we were unable to confirm your income, here is 2023's part B premium ranges. If you're interested in immunosuppressive drugs only care, please`}
                            <a
                                href="https://www.cms.gov/newsroom/fact-sheets/2023-medicare-parts-b-premiums-and-deductibles-2023-medicare-part-d-income-related-monthly"
                                target={'_blank'}
                                rel="noreferrer"
                            >
                                {' '}
                                click here{' '}
                            </a>
                            {`to navigate to medicare's site and review a different chart. `}
                        </h4>
                    </Grid>
                    <Grid item sm={6}>
                        {this.maybeRenderIllustration()}
                    </Grid>
                </Grid>
            );
        } else {
            return (
                <div>
                    {this.maybeRenderIcon()}
                    <Header>{name}</Header>
                    <h3>{description}</h3>
                    {this.maybeRenderIllustration()}
                </div>
            );
        }
    }

    renderMultipleOptions() {
        const { description, name } = this.props.data;
        return (
            <Container>
                {this.maybeRenderIcon()}
                <Header>{name}</Header>
                {<h2>{description}</h2>}

                <OptionsContainer>
                    {this.props.data.options
                        .filter(item => item.isActive)
                        .map(columnData => (
                            <OptionItem key={columnData.id}>
                                <Card key={columnData.id} text={columnData} />
                            </OptionItem>
                        ))}
                </OptionsContainer>
                {this.maybeRenderIllustration()}
            </Container>
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.props.data.options.filter(item => item.isActive)
                    .length === 0
                    ? this.renderSingleOption()
                    : this.renderMultipleOptions()}
            </React.Fragment>
        );
    }
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const OptionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 312px;
`;

const OptionItem = styled.div`
    margin: 0 24px 54px 0;
`;

const Header = styled.h1`
    font-size: 72px;
    line-height: 99px;
    ${media.lessThan('medium')`
        font-size: 48px;
        line-height: 66px;
    `}
    margin-top: 32px;
`;

const IconImg = styled.img`
    float: left;
    max-width: 200px;
    height: auto;
`;

const IllustrationImg = styled.img`
    float: left;
    margin-top: 50px;
    max-width: 500px;
    height: auto;
`;

export default ConversationHostComponent;
