import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';

import './index.scss';

const calculateActiveGroups = percent => {
    let activeGroup = 0;

    if (percent > 25 && percent < 51) {
        activeGroup = 1;
    } else if (percent > 50 && percent < 76) {
        activeGroup = 2;
    } else if (percent > 75) {
        activeGroup = 3;
    }

    return activeGroup;
};

const ProgressBar = ({ percent = 10, titles = [] }) => {
    const activeGroup = calculateActiveGroups(percent);
    return (
        <div className="progress-bar">
            {percent &&
            typeof percent !== 'object' &&
            typeof percent !== 'undefined' ? (
                <>
                    {!isMobile ? (
                        <div className="question-titles">
                            {titles
                                ? titles.map((title, i) => (
                                      <span
                                          className="title"
                                          key={title}
                                          style={{
                                              fontWeight:
                                                  i <= activeGroup
                                                      ? '600'
                                                      : 'normal',
                                              opacity:
                                                  i <= activeGroup
                                                      ? '1'
                                                      : '0.5',
                                          }}
                                      >
                                          {title}
                                      </span>
                                  ))
                                : null}
                        </div>
                    ) : null}
                    <div className="outer-bar">
                        <div
                            className="inner-bar"
                            style={{ width: `${percent}%` }}
                        ></div>
                    </div>
                </>
            ) : null}
        </div>
    );
};

ProgressBar.propTypes = {
    percent: PropTypes.number.isRequired,
    titles: PropTypes.array,
};

export default ProgressBar;
