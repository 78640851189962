import React, { Component, Fragment } from 'react';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import services from '../../../../services';
import { Grid } from '@material-ui/core';
import Input from '../../../shared/Input/Input';
import Button from '../../../shared/Button/Button';
import ErrorMessage from '../../../shared/ErrorMessage/ErrorMessage';
import Footer from '../../../shared/Footer/Footer';
import { get } from 'lodash';
import { getEntry } from '../../../../services/contentful';

const MIN_LENGTH = 5;
let tipingTimeout;
const zipMask = [/\d/, /\d/, /\d/, /\d/, /\d/];

class ZipCode extends Component {
    constructor(props) {
        super(props);

        this.state = {
            zipCode: '',
            countyId: '',
            usableCounties: [],
            showCountySelection: false,
            nonUsableCounties: [],
            invalidZip: null,
            imageUrl: '',
        };

        const footerProps = {
            hasNextButton: false,
        };
        props.triggerFooterUpdate(footerProps);
    }

    async componentDidMount() {
        if (this.countySelection) {
            this.countySelection.scrollIntoView({ behavior: 'smooth' });
        }

        const entry = await getEntry({
            id: this.props.data.conversationItemId,
        });
        if (entry) {
            this.setState({
                ...this.state,
                imageUrl: `https://${entry.fields.illustrationUrl.fields.file.url}?w=350`,
            });
        }
    }

    onChangeZipCode = event => {
        clearTimeout(tipingTimeout);

        const targetValue = event.target.value;
        if (targetValue.length === MIN_LENGTH) {
            tipingTimeout = setTimeout(async () => {
                const countyListResult = await services.county.findByZipCode(
                    targetValue
                );

                const usableCountyList = countyListResult.usable;
                const nonUsableCountyList = countyListResult.nonUsable;

                if (
                    usableCountyList.length === 0 &&
                    nonUsableCountyList.length === 0
                ) {
                    return this.setState({
                        invalidZip: this.props.intl.formatMessage({
                            id: 'zipCode.invalid',
                        }),
                    });
                } else {
                    this.setState({
                        invalidZip: null,
                    });
                }

                const allCounties = usableCountyList.concat(
                    nonUsableCountyList
                );

                this.setState({
                    nonUsableCounties: nonUsableCountyList,
                    usableCounties: usableCountyList,
                });

                // If there is one and only one usableCounty and no non-usable counties
                // then advance them
                if (allCounties.length === 1) {
                    this.saveSelection(allCounties[0]);
                } else {
                    // If there is more than one county then present the county selection
                    // (their zip code might match a usable county, but they actually live
                    // in a non-usable county)
                    this.setState({
                        showCountySelection: true,
                    });
                }
            }, 1000);

            this.setState({
                zipCode: targetValue,
            });
        }
    };

    saveSelection = async value => {
        const { goToNextQuestion } = this.props;

        const nextConversationItemId = get(
            this.props,
            'data.edges[0].nextConversationItemId'
        );

        const { conversationItemId } = this.props.data;
        const answer = this.state.zipCode;
        const secondaryAnswer = value.name;

        try {
            await services.conversation.saveResponse({
                questionId: conversationItemId,
                answer: [
                    {
                        conversationItemId,
                        answer,
                        secondaryAnswer,
                    },
                ],
                geoState: answer,
            });
        } catch (e) {
            console.log(e);
        }

        if (
            this.state.usableCounties.filter(c => c.id === value.id).length > 0
        ) {
            goToNextQuestion({ id: nextConversationItemId });
        } else {
            goToNextQuestion({
                redirectPath: `/conversation/ineligible-county?county=${value.name}`,
            });
        }
    };

    showCountySelection = () => {
        const allCounties = this.state.usableCounties.concat(
            this.state.nonUsableCounties
        );

        return (
            <CountySelectionContainer ref={ref => (this.countySelection = ref)}>
                <CountySelectionDescription>
                    <FormattedMessage id="zipCode.foundMultipleCounties" />
                </CountySelectionDescription>
                <ButtonContainer>
                    {allCounties.map(c => (
                        <ButtonWrapper key={c.id}>
                            <Button
                                key={c.id}
                                text={c.name}
                                callback={() => this.saveSelection(c)}
                                buttonType={'rect-transparent'}
                                size={'medium'}
                            />
                        </ButtonWrapper>
                    ))}
                </ButtonContainer>
            </CountySelectionContainer>
        );
    };

    showZipCodeInput = () => {
        return (
            <Fragment>
                <Grid container justifyContent="space-between" spacing="3">
                    <Grid item sm={6}>
                        <MaskedInput
                            mask={zipMask}
                            placeholder={this.props.intl.formatMessage({
                                id: 'placeholder.zipCode',
                            })}
                            guide={false}
                            onChange={this.onChangeZipCode}
                            placeholderChar={' '}
                            render={(ref, props) => (
                                <React.Fragment>
                                    <Input
                                        ref={ref}
                                        callback={props.onChange}
                                        value={props.defaultValue}
                                        textPosition={'left'}
                                        name={'zipCode'}
                                        {...props}
                                    />
                                </React.Fragment>
                            )}
                        />
                    </Grid>

                    <Grid item sm={6}>
                        {this.state.imageUrl && (
                            <div>
                                <img src={this.state.imageUrl} />
                            </div>
                        )}
                    </Grid>
                </Grid>
            </Fragment>
        );
    };

    render() {
        return (
            <div>
                {this.showZipCodeInput()}
                {this.state.invalidZip ? (
                    <ErrorMessage
                        isShown={!!this.state.invalidZip}
                        text={
                            this.state.invalidZip ? this.state.invalidZip : ''
                        }
                    />
                ) : null}
                {this.state.showCountySelection
                    ? this.showCountySelection()
                    : null}
                <Footer hasNextButton={false} />
            </div>
        );
    }
}

export const CountySelectionContainer = styled.div`
    margin-top: 36px;
`;

const CountySelectionDescription = styled.h3`
    color: #2b223b;
    font-size: 24px;
    font-family: 'Source Sans Pro';
    font-weight: 600;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 36px;
`;

const ButtonWrapper = styled.div`
    margin-right: 20px;
`;

export default withRouter(injectIntl(ZipCode));
