import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IconButton, Dialog, Slide } from '@material-ui/core';

import CancelIcon from './../../Conversation/QuestionTypes/cancel-icon.svg';
import Button from '../../shared/Button/Button.js';
import './index.scss';
import Logo from './logo.svg';
import ProgressBar from '../ProgressBar/ProgressBar';
import FAQ from './../../Conversation/QuestionTypes/FAQ';

const fsUnmask = 'fs-unmask';
class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            faqFlag: true,
            faqOpen: false,
        };
    }

    toggleFaq = () => {
        console.log('toggled');
        this.setState({
            faqOpen: !this.state.faqOpen,
        });
    };

    closeFaq = () => {
        this.setState({
            faqOpen: false,
        });
    };

    render() {
        return (
            <div className="navbar-wrapper">
                <img src={Logo} alt="Entermedicare logo" />
                {this.props.progressBarIsVisible ? (
                    <ProgressBar
                        percent={this.props.progressBarValue}
                        titles={this.props.titles}
                    />
                ) : null}
                {this.state.faqFlag && !this.props.hideFaqButton ? (
                    <Button
                        name="faqButton"
                        text={'FAQ'}
                        onClick={() => this.toggleFaq()}
                        style={{ fontSize: 20 }}
                        className={fsUnmask + ' navbar-faq'}
                        color={'#e8e8e8'}
                        buttonType="rect-transparent"
                    />
                ) : null}
                <Dialog
                    fullScreen
                    open={this.state.faqOpen}
                    onClose={this.closeFaq}
                    TransitionComponent={Slide}
                >
                    <IconButton
                        className={'navbar-faq-close'}
                        edge="start"
                        color="inherit"
                        onClick={this.closeFaq}
                        aria-label="close"
                    >
                        <img src={CancelIcon} alt="Cancel Icon" />
                    </IconButton>
                    <FAQ
                        goToNextQuestion={() => {}}
                        triggerFooterUpdate={() => {}}
                    />
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    progressBarValue: state.progressBarValue,
    progressBarIsVisible:
        state.progressBarValue === 0 || state.progressBarValue > 0,
});

export default connect(mapStateToProps)(NavBar);
