import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import services from '../../services';
import queryString from 'query-string';
import { ascentUrlForEnv, employeeCenterUrlForEnv } from '../../utils';
import Footer from '../../components/shared/Footer/Footer';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { updateProgressBarValue } from '../../actions';

import { getQuoteData } from '../../services/plan';

class ReviewInfo extends Component {
    state = {
        items: [],
        isLoading: true,
        quoteIds: [],
    };

    async componentDidMount() {
        let { quoteIds, accessToken, planToken } = queryString.parse(
            this.props.location.search
        );
        let result;
        if (!quoteIds || !planToken) {
            result = await getQuoteData(accessToken);
            quoteIds = result.quoteIds;
        } else {
            result = await services.conversation.reviewYourInfo(planToken);
            quoteIds = quoteIds.split(',');
        }
        this.setState({
            items: result.recData,
            isLoading: false,
            quoteIds: quoteIds,
            brokerId: result.brokerId,
            accessToken,
        });

        this.props.updateProgress(100);
    }

    backToProfile = () => {
        window.location.href = `${employeeCenterUrlForEnv()}/profile`;
    };

    linkToAscentPlans = async () => {
        const link = ascentUrlForEnv();
        localStorage.clear();
        window.location.href = link +
            `/thankyou?brokerId=${this.state.brokerId}&quoteId=${this.state.quoteIds[0]}`;
    };

    getImgForItem = item => {
        switch (item.type) {
            case 'DRUG':
                return {
                    img: 'https://d31vqacjwzpnz3.cloudfront.net/pills.png',
                    alt: 'pills',
                };
            case 'DOCTOR':
                return {
                    img: 'https://d31vqacjwzpnz3.cloudfront.net/doctor.png',
                    alt: 'doctor',
                };
            case 'BENEFIT':
                return {
                    img: 'https://d31vqacjwzpnz3.cloudfront.net/shoes.png',
                    alt: 'shoes',
                };
            case 'PREFERENCES':
                return {
                    img: 'https://d31vqacjwzpnz3.cloudfront.net/shoes.png',
                    alt: 'shoes',
                };
            default:
                return { img: '', alt: '' };
        }
    };

    render() {
        const { nextButtonText } = this.props;

        return (
            <ReviewInfoContainer>
                {this.state.isLoading && (
                    <CircularProgress className="spinner" />
                )}
                <ContentContainer>
                    <Typography
                        variant="h1"
                        style={{ marginBottom: '20px' }}
                    >{`Here's what we heard from you:`}</Typography>
                    <InfoItemsContainer>
                        {this.state.items.map((item, i) => {
                            const { img, alt } = this.getImgForItem(item);
                            return (
                                <InfoItemContainer key={i}>
                                    <Image src={img} alt={alt} />
                                    <Span dangerouslySetInnerHTML={item.text} />
                                </InfoItemContainer>
                            );
                        })}
                    </InfoItemsContainer>
                    <h3>
                        <b>Does this sound right?</b>
                    </h3>
                </ContentContainer>

                <Footer
                    backButtonCallback={
                        this.state.accessToken ? this.backToProfile : undefined
                    }
                    hasNextButton
                    saveButtonHidden
                    nextButtonText={nextButtonText}
                    nextCallback={this.linkToAscentPlans}
                />
            </ReviewInfoContainer>
        );
    }
}

const ReviewInfoContainer = styled.div``;

const ContentContainer = styled.div`
    margin: 32px 12.5% 150px 16.667%;
    @media (max-width: 450px) {
        margin: 32px 16px 150px 16px;
    }
`;

const InfoItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
    margin-top: -40px;
`;

const InfoItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 48px;
`;

const Image = styled.img`
    height: 64px;
`;

const Span = styled.span`
    margin-left: 40px;
    font-size: 24px;
    line-height: 32px;
`;

ReviewInfo.propTypes = {
    nextButtonText: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
    updateProgress: val => dispatch(updateProgressBarValue(val)),
});

export default withRouter(
    connect(null, mapDispatchToProps)(ReviewInfo)
);
