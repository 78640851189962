const root = (state = [], action) => {
    switch (action.type) {
        case 'UPDATE_PROG_VALUE':
            return {
                ...state,
                progressBarValue: action.progressBarValue,
            };
        case 'SET_BROKER_ID':
            return {
                ...state,
                brokerId: action.brokerId,
            };
        case 'SET_ACCESS_TOKEN':
            return {
                ...state,
                accessToken: action.accessToken,
            };
        case 'SET_REFERENCE_CODE':
            return {
                ...state,
                referenceCode: action.referenceCode,
            };
        default:
            return state;
    }
};

export default root;
