import es from './translations/es.json';
import en from './translations/en.json';

const supportedLanguages = ['en', 'es'];

export const deriveLangFromBrowser = () => {
    const derivedLang = navigator.language.split(/[-_]/)[0];
    if (supportedLanguages.includes(derivedLang)) {
        return derivedLang;
    }
    return 'en';
};

// If we add more than just spanish, just import the files and export it below: export default { en, es, de }
export default { en, es };
