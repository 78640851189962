import Cookies from 'js-cookie';
import uuidv4 from 'uuid/v4';

export const getCookieDomain = () => {
    let domain = '';
    if (process.env.REACT_APP_EM_ENV === 'prod') {
        domain = '.entermedicare.com';
    } else if (process.env.REACT_APP_EM_ENV === 'dev') {
        domain = '.entermedicare-dev.com';
    }
    return domain;
};

export const setTrackingCookies = () => {
    let sessionId = Cookies.get('sid');
    let trackingId = Cookies.get('tid');

    if (!sessionId) {
        sessionId = uuidv4();
        Cookies.set('sid', sessionId, {
            expires: 1,
            domain: getCookieDomain(),
        }); // 1 day
    }

    if (!trackingId) {
        trackingId = uuidv4();
        Cookies.set('tid', trackingId, {
            expires: 30,
            domain: getCookieDomain(),
        }); // 30 days
    }
};

export const getTrackingIds = () => {
    const sessionId = Cookies.get('sid');
    const trackingId = Cookies.get('tid');
    return { sessionId, trackingId };
};

export const isAuthenticated = () => {
    return Cookies.get('accessToken');
};

export const ascentUrlForEnv = () => {
    if (process.env.REACT_APP_EM_ENV === 'prod') {
        return isAuthenticated()
            ? 'https://plans.entermedicare.com'
            : 'https://app.producerascent.com';
    } else if (process.env.REACT_APP_EM_ENV === 'dev') {
        return isAuthenticated()
            ? 'https://plans.entermedicare-dev.com'
            : 'https://broker.entermedicare-dev.com';
    } else {
        return 'http://localhost:3005';
    }
};

export const employeeCenterUrlForEnv = () => {
    if (process.env.REACT_APP_EM_ENV === 'prod') {
        return 'https://learn.entermedicare.com';
    } else if (process.env.REACT_APP_EM_ENV === 'dev') {
        return 'https://learn.entermedicare-dev.com';
    } else {
        return 'http://localhost:2999';
    }
};
