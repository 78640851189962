import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import translations, { deriveLangFromBrowser } from './i18n';
import { store, persistor } from './configureStore';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

Sentry.init({
    dsn:
        'https://a21126d068fb49fab1f4ca7a71cd9b23@o340186.ingest.sentry.io/5305089',
    whitelistUrls: [/(https?:\/\/)?((.*)?\.)?entermedicare\.com/],
});

// navigator.language returns region as well i.e. en-US or en-GB, but we don't want that for now
const language = deriveLangFromBrowser();

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <IntlProvider
                defaultLocale="en"
                locale={language}
                key={language}
                messages={translations[language]}
            >
                <App />
            </IntlProvider>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
