import { find, flattenDeep, get } from 'lodash';
import { fetchUser } from './services/user';
import queryString from 'querystring';

const setConversationState = ({
    questionId,
    answer,
    replacementKey,
    replacementValue,
    forSomeoneElse,
    geoState,
    client,
    broker,
    subdomain,
}) => {
    let conversationState = JSON.parse(
        localStorage.getItem('conversationState')
    );

    if (!conversationState) {
        conversationState = {
            answers: {},
            replacements: {},
        };

        localStorage.setItem(
            'conversationState',
            JSON.stringify(conversationState)
        );

        conversationState = JSON.parse(
            localStorage.getItem('conversationState')
        );
    }

    if (questionId) {
        conversationState.answers[questionId] = answer;
        conversationState.questionId = questionId;
    }

    if (replacementKey && replacementValue) {
        if (!conversationState.replacements) {
            conversationState.replacements = {};
        }

        conversationState.replacements[replacementKey] = replacementValue;
    }

    if (forSomeoneElse !== undefined) {
        conversationState.forSomeoneElse = forSomeoneElse;
    }

    if (geoState) {
        conversationState.geoState = geoState;
    }

    if (client) {
        conversationState.client = client;
    }

    if (broker) {
        conversationState.broker = broker;
    }

    if (subdomain) {
        conversationState.subdomain = subdomain;
    }

    localStorage.setItem(
        'conversationState',
        JSON.stringify(conversationState)
    );
};

const isThirdPerson = () => {
    let conversationState = JSON.parse(
        localStorage.getItem('conversationState')
    );

    return conversationState.forSomeoneElse
        ? conversationState.forSomeoneElse
        : false;
};

const getClient = () => {
    let conversationState = JSON.parse(
        localStorage.getItem('conversationState')
    );

    if (!conversationState) {
        return '';
    }

    return conversationState.client;
};

const getSubdomain = () => {
    const host = get(window, 'location.host');
    if (host.split('.').length < 1) return null;
    else return host.split('.')[0];
};

const getAnswers = () => {
    let conversationState = JSON.parse(
        localStorage.getItem('conversationState')
    );

    if (!conversationState) {
        return [];
    }

    return flattenDeep(Object.values(conversationState.answers));
};

const getLastAnsweredQuestionId = () => {
    let conversationState = JSON.parse(
        localStorage.getItem('conversationState')
    );

    if (!conversationState) {
        return '';
    }

    return conversationState.questionId;
};

const getGeoState = async () => {
    // rely on zipcode in settings first, then localstorage
    const { accessToken } = queryString.parse(window.location.search);

    const user = await fetchUser(accessToken);

    let conversationState = JSON.parse(
        localStorage.getItem('conversationState')
    );

    return Number(user.zipCode) || conversationState.geoState;
};

const getEnrolledInMedicare = () => {
    const conversationStateRaw = JSON.parse(
        localStorage.getItem('conversationState')
    );
    const conversationState = flattenDeep(
        Object.values(get(conversationStateRaw, 'answers', []))
    );
    return find(conversationState, a => {
        if (
            get(a, 'answer', '').indexOf('partAEnrollmentDate') >= 0 ||
            get(a, 'answer', '').indexOf('partBEnrollmentDate') >= 0
        ) {
            return true;
        } else {
            return false;
        }
    });
};

export {
    setConversationState as default,
    isThirdPerson,
    getClient,
    getAnswers,
    getLastAnsweredQuestionId,
    getGeoState,
    getSubdomain,
    getEnrolledInMedicare,
};
