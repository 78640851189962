import { createClient } from 'contentful';
import ConversationItem from '../models/ConversationItem';
let client;
// We don't need a Contentful client for cypress/percy tests

if (
    process.env.REACT_APP_CONTENTFUL_DELIVERY_API_KEY &&
    process.env.REACT_APP_CONTENTFUL_SPACE_ID
) {
    client = createClient({
        space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
        environment:
            process.env.REACT_APP_EM_ENV === 'prod' ? 'master' : 'develop',
        accessToken: process.env.REACT_APP_CONTENTFUL_DELIVERY_API_KEY,
    });
}

export const getEntries = async ({ entryIds }) => {
    try {
        const response = await client.getEntries({
            content_type: 'conversationItem',
            'sys.id[in]': entryIds.join(),
            include: 2,
        });
        return response.items.map(entry => new ConversationItem({ entry }));
    } catch (e) {
        console.log(e);
        return [];
    }
};

export const getEntry = async ({ id }) => {
    try {
        const result = await client.getEntry(id);
        return result;
    } catch (e) {
        console.log(e);
    }
};
