import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import { TextField as TextFieldFormik } from 'formik-material-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';

import { personalDataSchema } from '../utils/validationSchema';
import services from '../../services';
import { findErrorField } from '../utils/helper';
import Disclaimer from '../shared/Disclaimer/Disclaimer';
import Footer from '../shared/Footer/Footer';
import { deriveLangFromBrowser } from '../../i18n';
import ErrorMessage from '../shared/ErrorMessage/ErrorMessage';
import { getAnswers } from '../../conversationState';

const Grid = styled.div`
    width: 100%;
    max-width: 467px;
`;

class PersonalDataComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMessage: '',
        };

        let conversationState = JSON.parse(
            localStorage.getItem('conversationState')
        );

        if (!conversationState) {
            conversationState = {};
        }

        this.conversationState = conversationState;
    }

    render() {
        let initialValues = {
            firstName: '',
            lastName: 'New Lead',
            email: '',
            phone: '',
        };
        const fsUnmask = 'fs-unmask';

        return (
            <div>
                <div className="question input-form">
                    <h1 className={fsUnmask}>
                        <FormattedMessage id="personalData.title" />
                    </h1>
                    <h3 className={fsUnmask}>
                        <FormattedMessage id="personalData.desc" />
                    </h3>

                    <Grid>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={personalDataSchema}
                            validateOnChange={true}
                            onSubmit={(
                                values,
                                { setSubmitting, setFieldError, resetForm }
                            ) => {
                                services.user
                                    .throttledSubmitData({
                                        ...values,
                                        lastAnsweredQuestionId: this
                                            .conversationState.questionId,
                                        answers: getAnswers(),
                                        locale: deriveLangFromBrowser(),
                                    })
                                    .then(data => {
                                        const planToken = data.planToken;
                                        setSubmitting(false);
                                        resetForm();
                                        this.props.history.push(
                                            `/conversation/review-your-info?quoteIds=${data.quoteIds.toString()}&planToken=${planToken}`
                                        );
                                    })
                                    .catch(err => {
                                        findErrorField(err, setFieldError);
                                        const errorMessage =
                                            err.type === 'CUSTOM_ERROR'
                                                ? this.props.intl.formatMessage(
                                                      {
                                                          id:
                                                              'personalData.emailExists',
                                                      }
                                                  )
                                                : this.props.intl.formatMessage(
                                                      {
                                                          id:
                                                              'personalData.error',
                                                      }
                                                  );

                                        this.setState({
                                            errorMessage: errorMessage,
                                        });
                                    });
                                setSubmitting(false);
                            }}
                            render={({ submitForm, isSubmitting }) => (
                                <Form autocomplete="new-password">
                                    <Field
                                        required
                                        autoComplete="firstName"
                                        fullWidth
                                        name="firstName"
                                        type="text"
                                        placeholder={this.props.intl.formatMessage(
                                            { id: 'personalData.firstName' }
                                        )}
                                        component={TextFieldFormik}
                                        className="new-input-field fs-block"
                                        data-hj-suppress
                                    />

                                    {/* <Field
                                        required
                                        autoComplete="lastName"
                                        fullWidth
                                        name="lastName"
                                        type="text"
                                        placeholder={this.props.intl.formatMessage(
                                            { id: 'personalData.lastName' }
                                        )}
                                        component={TextFieldFormik}
                                        className="new-input-field fs-block"
                                    /> */}

                                    <Field
                                        required
                                        name="email"
                                        fullWidth
                                        component={TextFieldFormik}
                                        className="new-input-field fs-block"
                                        type="text"
                                        placeholder={this.props.intl.formatMessage(
                                            {
                                                id: 'placeholder.email',
                                            }
                                        )}
                                        data-hj-suppress
                                    />

                                    <Field
                                        required
                                        name="phone"
                                        type="text"
                                        placeholder={this.props.intl.formatMessage(
                                            {
                                                id: 'placeholder.phone',
                                            }
                                        )}
                                        fullWidth
                                        component={TextFieldFormik}
                                        className="new-input-field fs-block"
                                        data-hj-suppress
                                    />

                                    <ErrorMessage
                                        isShown={
                                            this.state.errorMessage.length > 0
                                        }
                                        text={this.state.errorMessage}
                                    />

                                    <Footer
                                        hasNextButton
                                        nextButtonText={
                                            <FormattedMessage id="button.next" />
                                        }
                                        nextCallback={submitForm}
                                        disabled={isSubmitting}
                                    />
                                    <Disclaimer />
                                </Form>
                            )}
                        />
                    </Grid>
                </div>
            </div>
        );
    }
}

export default withRouter(injectIntl(PersonalDataComponent));
