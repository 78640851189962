import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';

import Footer from '../../components/shared/Footer/Footer';

import Check from './check.svg';
import MedicareCard from './medicareCard.svg';

class EnrollmentPrepPage extends Component {
    render() {
        const { intl, nextButtonText, nextButtonCallback } = this.props;

        return (
            <div>
                <ContentContainer>
                    <Title>
                        {intl.formatMessage({
                            id: 'enrollmentPrepPage.title',
                        })}
                    </Title>

                    <InfoItemContainer>
                        <ItemText>
                            {intl.formatMessage({
                                id: 'enrollmentPrepPage.info1',
                            })}
                        </ItemText>
                        <ItemImg src={MedicareCard} />
                    </InfoItemContainer>
                    <InfoItemContainer>
                        <ItemText>
                            {intl.formatMessage({
                                id: 'enrollmentPrepPage.info2',
                            })}
                        </ItemText>
                        <ItemImg src={Check} />
                    </InfoItemContainer>
                </ContentContainer>

                <Footer
                    hasNextButton
                    saveButtonHidden
                    nextButtonText={nextButtonText}
                    nextCallback={nextButtonCallback}
                />
            </div>
        );
    }
}

EnrollmentPrepPage.propTypes = {
    nextButtonText: PropTypes.string,
    nextButtonCallback: PropTypes.func,
};

const ContentContainer = styled.div`
    margin: 45px 12.5% 150px 16.667%;
`;

const Title = styled.h1`
    font-family: 'Le Monde Livre Pro';
    font-size: 48px;
    line-height: 66px;
    color: #041f1e;
    font-weight: bold;
`;

const InfoItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 36px;
`;

const ItemText = styled.span`
    font-family: 'Source Sans Pro';
    font-size: 32px;
    line-height: 48px;
    color: #041f1e;
    font-weight: normal;
    margin-right: 15px;
`;

const ItemImg = styled.img`
    max-width: 173px;
`;

export default withRouter(injectIntl(EnrollmentPrepPage));
