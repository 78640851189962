import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const Card = ({ text }) => (
    <div className="card">
        <h3>{text.option}</h3>
        <p>{text.description}</p>
    </div>
);

Card.propTypes = {
    text: PropTypes.object.isRequired,
};

export default Card;
