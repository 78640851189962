import React, { Component } from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import { find, get } from 'lodash';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import './index.scss';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { updateProgressBarValue } from '../../actions';

// COMPONENTS
import linearComponent from './QuestionTypes/Linear';
import buttonsComponent from './QuestionTypes/BUTTONS';
import autoCompleteDropDownComponent from './QuestionTypes/AUTOCOMPLETE_DROP_DOWN';
import checkboxesComponent from './QuestionTypes/CHECKBOXES';
import birthDateComponent from './QuestionTypes/BirthDate';
import brokerComponent from './QuestionTypes/BrokerConversation';
import calendlyComponent from './QuestionTypes/Calendly';
import zipCodeComponent from './QuestionTypes/ZipCode';
import groupComponent from './QuestionTypes/GROUP';
import phoneComponent from './QuestionTypes/text/Phone';
import currencyComponent from './QuestionTypes/text/Currency';
import numberComponent from './QuestionTypes/text/Number';
import textComponent from './QuestionTypes/Text';
import videoComponent from './QuestionTypes/Video';
import enrollmentComponent from './QuestionTypes/Enrollment';
import faqComponent from './QuestionTypes/FAQ';
import pharmacyComponent from './QuestionTypes/Pharmacy';
import employerComponent from './QuestionTypes/text/Employer';
import styled from 'styled-components';
import Footer from '../shared/Footer/Footer';
import services from '../../services';
import { getAnswers, getLastAnsweredQuestionId } from '../../conversationState';
import { deriveLangFromBrowser } from '../../i18n';
// COMPONENTS END
import { employeeCenterUrlForEnv } from '../../utils';
import { getEntries } from '../../services/contentful';
import {
    fetchMonthlyPremium,
    fetchYearlyDeductible,
    fetchOutOfPocketMax,
    fetchCurrentPlanName,
} from '../../services/user';
import { getConversationByUrlParam } from '../../services/conversation';

const HeaderContent = styled.div`
    white-space: pre-wrap;
    margin-top: 20px;
`;

class ConversationHostComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            footerProps: {
                hasNextButton: false,
                skipButton: true,
            },
            currentQuestion: {},
            isShown: true,
            backAnimation: false,
            skipReview: false,
            isSingleQuestion: false,
            backButtonHidden: false,
            isFirstQuestion: false,
            prefillData: null,
            bookFirst: false,
        };
    }

    components = {
        AUTOCOMPLETE_DROP_DOWN: autoCompleteDropDownComponent,
        BIRTH_DATE: birthDateComponent,
        BUTTONS: buttonsComponent,
        CHECKBOXES: checkboxesComponent,
        CURRENCY: currencyComponent,
        GROUP: groupComponent,
        LINEAR: linearComponent,
        NUMBER: numberComponent,
        PHONE: phoneComponent,
        TEXT: textComponent,
        FAQ: faqComponent,
        ZIP_CODE: zipCodeComponent,
        BROKER_FORM: brokerComponent,
        CALENDLY: calendlyComponent,
        VIDEO: videoComponent,
        EMPLOYER: employerComponent,
        ENROLLMENT_DATES: enrollmentComponent,
        PHARMACY: pharmacyComponent,
    };

    getQuestion = async questionId => {
        const entry = await getEntries({ entryIds: [questionId] });
        if (Array.isArray(entry) && entry.length) return entry[0];
        else return {};
    };

    componentDidMount = async () => {
        const {
            skipReview,
            isSingleQuestion,
            backButtonHidden,
        } = queryString.parse(this.props.location.search);
        const questionId = this.props.match.params.id;
        const contentfulQuestion = await this.getQuestion(questionId);

        // determine routing here
        const edge = find(this.props.conversationRouting, {
            conversationItemId: questionId,
        });
        const currentQuestion = { ...edge, ...contentfulQuestion };
        // need to add progressValue back in.
        this.props.updateProgress(currentQuestion.progressValue);

        await this.fetchAdditionalPremiumData(currentQuestion.decisionType);

        this.setState({
            skipReview,
            isSingleQuestion,
            currentQuestion,
            backButtonHidden: backButtonHidden === '1' ? true : false,
            isFirstQuestion:
                window.location.href.indexOf(
                    this.props.conversation.firstItemId
                ) !== -1
                    ? true
                    : false,
        });
    };

    fetchAdditionalPremiumData = async decisionType => {
        //fetch additional data to prefill answers if necessary
        let prefillData = null;
        switch (decisionType) {
            case 'MONTHLY_COVERAGE_PREMIUM':
                prefillData = await fetchMonthlyPremium(this.props.accessToken);
                break;
            case 'YEARLY_DEDUCTIBLE':
                prefillData = await fetchYearlyDeductible(
                    this.props.accessToken
                );
                break;
            case 'OOP_MAX':
                prefillData = await fetchOutOfPocketMax(this.props.accessToken);
                break;
            case 'CURRENT_PLAN_NAME':
                prefillData = await fetchCurrentPlanName(
                    this.props.accessToken
                );
                break;
            default:
                break;
        }
        this.setState({ prefillData });
        //TEMP
        // this.setState({prefillData: 500.00})
    };

    headerContent = () => {
        const { name, description } = this.state.currentQuestion;
        const fsUnmask = 'fs-unmask';
        return (
            <HeaderContent>
                <Typography
                    className={fsUnmask}
                    variant="h1"
                    style={{ marginBottom: '20px' }}
                >
                    {name}
                </Typography>
                <h3 className={fsUnmask}>{description}</h3>
            </HeaderContent>
        );
    };

    goToNextQuestion = ({ id, redirectPath, redirectUrl, bookFirst }) => {
        /**
         * @param id
         * If there is no nextQuestionId user will be redirect to personal data page
         *
         * @param redirectPath
         * If there is a special re-route path (i.e. to a dead end page for birthdate or zip code)
         * then redirect them to the appropriate page. Redirect is within this app, a path e.g. /special-path
         * @param redirectUrl
         * For a redirect outside this app e.g. https://www.google.com
         */
        const {
            history: { push },
        } = this.props;
        this.setState(
            {
                isShown: false,
                backAnimation: false,
                ...(bookFirst && { bookFirst }),
            },
            () => {
                // TODO: Could setting state here be causing flicker?
                localStorage.setItem('navigatedBack', '0');
                setTimeout(async () => {
                    if (this.state.isSingleQuestion) {
                        // isSingleQuestion will be present when editing a question from the employee center
                        // we don't want the user to need to go through the whole conversation again
                        await this.finishConversation({
                            redirectUrl: `${employeeCenterUrlForEnv()}/profile`,
                        });
                    } else if (redirectPath) {
                        push(redirectPath);
                    } else if (id) {
                        push(
                            `/conversation/question/${id}?conversationId=${this.props.conversation.name}&accessToken=${this.props.accessToken}`
                        );
                    } else {
                        await this.finishConversation({ redirectUrl });
                    }
                }, 500);
            }
        );
    };

    skipThisQuestion = ({ id }) => {
        const {
            history: { push },
        } = this.props;

        setTimeout(async () => {
            push(`/conversation/question/${id}`);
        });
    };

    finishConversation = async ({ redirectUrl, skipOnboarding }) => {
        console.log('in finishConversation');
        if (skipOnboarding) {
            window.location.href = employeeCenterUrlForEnv();
        } else {
            let conversation = await getConversationByUrlParam();
            let conversationName = get(conversation, 'name', null);
            if (this.props.accessToken) {
                if (window.hj) {
                    window.hj('event', 'conversation-finished');
                }
                await services.user.throttledSubmitData({
                    form: {
                        lastAnsweredQuestionId: getLastAnsweredQuestionId(),
                        answers: getAnswers(),
                        ...(conversationName && { conversationName }),
                        locale: deriveLangFromBrowser(),
                        skipQuote: redirectUrl ? 1 : 0,
                        brokerId: this.props.brokerId,
                        referenceCode: this.props.referenceCode,
                    },
                    accessToken: this.props.accessToken,
                });
                services.user.throttledSubmitData.cancel();
                if (redirectUrl === '') {
                    window.location.href =
                        employeeCenterUrlForEnv() +
                        `/?bookFirst=${this.state.bookFirst}`;
                } else if (redirectUrl) {
                    let splitUrl = redirectUrl.split('/');
                    let path = splitUrl.pop();
                    // if path is a number then it must be a course
                    if (!isNaN(path)) {
                        path = `${splitUrl.pop()}/${path}`;
                    }
                    window.location.href =
                        employeeCenterUrlForEnv() +
                        `/${path}?bookFirst=${this.state.bookFirst}`;
                }
            }
        }
    };

    updateFooter = receievedFooterProps => {
        this.setState({ footerProps: receievedFooterProps });
    };

    generateTransitionName = () => {
        const navigatedBack = localStorage.getItem('navigatedBack');
        if (navigatedBack === '1' && this.state.backAnimation) {
            return 'slideintop-slideoutbottom';
        } else if (navigatedBack === '1' && !this.state.backAnimation) {
            return 'slideintop-slideouttop';
        } else {
            return 'slideinbottom-slideouttop';
        }
    };

    render() {
        const { currentQuestion, prefillData } = this.state;
        const QuestionComponent = this.components[currentQuestion.type];
        const fsUnmask = 'fs-unmask';
        return (
            <>
                <div className={'first-item'}>
                    <CSSTransitionGroup
                        transitionName={this.generateTransitionName()}
                        transitionEnterTimeout={1000}
                        transitionAppear
                        transitionLeaveTimeout={1000}
                        transitionAppearTimeout={1000}
                    >
                        {get(currentQuestion, 'type') !== 'VIDEO'
                            ? this.state.isShown &&
                              Object.prototype.hasOwnProperty.call(
                                  currentQuestion,
                                  'type'
                              ) && (
                                  <div className="question">
                                      {currentQuestion.type !== 'LINEAR' &&
                                          currentQuestion.type !== 'FAQ' &&
                                          this.headerContent()}
                                      <QuestionComponent
                                          className={currentQuestion.type}
                                          data={currentQuestion}
                                          goToNextQuestion={
                                              this.goToNextQuestion
                                          }
                                          skipThisQuestion={
                                              this.skipThisQuestion
                                          }
                                          triggerFooterUpdate={
                                              this.updateFooter
                                          }
                                          bookFirst={this.bookFirst}
                                          hideFaq={this.props.hideFaq}
                                          prefillData={prefillData}
                                          finishConversationCallback={
                                              this.finishConversation
                                          }
                                      />
                                  </div>
                              )
                            : this.state.isShown &&
                              Object.prototype.hasOwnProperty.call(
                                  currentQuestion,
                                  'type'
                              ) && (
                                  <div className="question">
                                      <QuestionComponent
                                          className={currentQuestion.type}
                                          data={currentQuestion}
                                          goToNextQuestion={
                                              this.goToNextQuestion
                                          }
                                          skipThisQuestion={
                                              this.skipThisQuestion
                                          }
                                          triggerFooterUpdate={
                                              this.updateFooter
                                          }
                                          hideFaq={this.props.hideFaq}
                                          prefillData={prefillData}
                                          finishConversationCallback={
                                              this.finishConversation
                                          }
                                      />
                                      {currentQuestion.type !== 'LINEAR' &&
                                          currentQuestion.type !== 'FAQ' && (
                                              <h3 className={fsUnmask}>
                                                  {
                                                      this.state.currentQuestion
                                                          .description
                                                  }
                                              </h3>
                                          )}
                                  </div>
                              )}
                    </CSSTransitionGroup>

                    <CSSTransitionGroup
                        transitionName="fade"
                        transitionEnterTimeout={1000}
                        transitionLeaveTimeout={1000}
                        transitionAppearTimeout={1000}
                        transitionAppear
                        transitionLeave
                    >
                        {this.state.isShown && (
                            <Footer
                                {...this.state.footerProps}
                                isFirstQuestion={this.state.isFirstQuestion}
                                backButtonHidden={this.state.backButtonHidden}
                                triggerAnimation={() =>
                                    this.setState({
                                        isShown: false,
                                        backAnimation: true,
                                    })
                                }
                            />
                        )}
                    </CSSTransitionGroup>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    updateProgress: val => dispatch(updateProgressBarValue(val)),
});

const mapStateToProps = state => ({
    brokerId: state.brokerId,
    accessToken: state.accessToken,
    referenceCode: state.referenceCode,
    bookFirst: state.bookFirst,
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ConversationHostComponent)
);
