import React from 'react';
import PropTypes from 'prop-types';

import Footer from '../Footer/Footer';

import './index.scss';

const InfoPage = ({ headline, paragraph, footer }) => {
    return (
        <div className="info-page-wrapper">
            <div className="content">
                <h1>{headline}</h1>
                <p>{paragraph}</p>
            </div>
            {footer ? footer() : <Footer hasNextButton={false} />}
        </div>
    );
};

InfoPage.propTypes = {
    headline: PropTypes.string.isRequired,
    paragraph: PropTypes.string,
    footer: PropTypes.func,
};

export default InfoPage;
