import { createTheme } from '@material-ui/core/styles';
export const states = [
    { name: 'Alabama', 'alpha-2': 'AL' },
    { name: 'Alaska', 'alpha-2': 'AK' },
    { name: 'Arizona', 'alpha-2': 'AZ' },
    { name: 'Arkansas', 'alpha-2': 'AR' },
    { name: 'California', 'alpha-2': 'CA' },
    { name: 'Colorado', 'alpha-2': 'CO' },
    { name: 'Connecticut', 'alpha-2': 'CT' },
    { name: 'Delaware', 'alpha-2': 'DE' },
    { name: 'District of Columbia', 'alpha-2': 'DC' },
    { name: 'Florida', 'alpha-2': 'FL' },
    { name: 'Georgia', 'alpha-2': 'GA' },
    { name: 'Hawaii', 'alpha-2': 'HI' },
    { name: 'Idaho', 'alpha-2': 'ID' },
    { name: 'Illinois', 'alpha-2': 'IL' },
    { name: 'Indiana', 'alpha-2': 'IN' },
    { name: 'Iowa', 'alpha-2': 'IA' },
    { name: 'Kansa', 'alpha-2': 'KS' },
    { name: 'Kentucky', 'alpha-2': 'KY' },
    { name: 'Lousiana', 'alpha-2': 'LA' },
    { name: 'Maine', 'alpha-2': 'ME' },
    { name: 'Maryland', 'alpha-2': 'MD' },
    { name: 'Massachusetts', 'alpha-2': 'MA' },
    { name: 'Michigan', 'alpha-2': 'MI' },
    { name: 'Minnesota', 'alpha-2': 'MN' },
    { name: 'Mississippi', 'alpha-2': 'MS' },
    { name: 'Missouri', 'alpha-2': 'MO' },
    { name: 'Montana', 'alpha-2': 'MT' },
    { name: 'Nebraska', 'alpha-2': 'NE' },
    { name: 'Nevada', 'alpha-2': 'NV' },
    { name: 'New Hampshire', 'alpha-2': 'NH' },
    { name: 'New Jersey', 'alpha-2': 'NJ' },
    { name: 'New Mexico', 'alpha-2': 'NM' },
    { name: 'New York', 'alpha-2': 'NY' },
    { name: 'North Carolina', 'alpha-2': 'NC' },
    { name: 'North Dakota', 'alpha-2': 'ND' },
    { name: 'Ohio', 'alpha-2': 'OH' },
    { name: 'Oklahoma', 'alpha-2': 'OK' },
    { name: 'Oregon', 'alpha-2': 'OR' },
    { name: 'Pennsylvania', 'alpha-2': 'PA' },
    { name: 'Rhode Island', 'alpha-2': 'RI' },
    { name: 'South Carolina', 'alpha-2': 'SC' },
    { name: 'South Dakota', 'alpha-2': 'SD' },
    { name: 'Tennessee', 'alpha-2': 'TN' },
    { name: 'Texas', 'alpha-2': 'TX' },
    { name: 'Utah', 'alpha-2': 'UT' },
    { name: 'Vermont', 'alpha-2': 'VT' },
    { name: 'Virginia', 'alpha-2': 'VA' },
    { name: 'Washington', 'alpha-2': 'WA' },
    { name: 'West Virginia', 'alpha-2': 'WV' },
    { name: 'Wisconsin', 'alpha-2': 'WI' },
    { name: 'Wyoming', 'alpha-2': 'WY' },
];

export const incomeOptions = [
    {
        label: 'Single: $88,000 or less',
        value: 'LTE_85000',
        filingType: 'SINGLE',
        conversationItemId: '7j9vZYC6YDAwCW6wIEg1nh',
        conversationItemOptionId: '7IZyCs15GCWpJl0akWpNt8',
    },
    {
        label: 'Single: $88,000 - $111,000',
        value: 'BTW_85000_107000',
        filingType: 'SINGLE',
        conversationItemId: '7j9vZYC6YDAwCW6wIEg1nh',
        conversationItemOptionId: '2E8ztvvMa5xwHjm2m7iCVV',
    },
    {
        label: 'Single: $111,000 - $138,000',
        value: 'BTW_107000_133500',
        filingType: 'SINGLE',
        conversationItemId: '7j9vZYC6YDAwCW6wIEg1nh',
        conversationItemOptionId: '35B08IIktN3ErtvPD9gJTX',
    },
    {
        label: 'Single: $138,000 - $165,000',
        value: 'BTW_133500_160000',
        filingType: 'SINGLE',
        conversationItemId: '7j9vZYC6YDAwCW6wIEg1nh',
        conversationItemOptionId: '3QmHS8zmEPKH0GOijwYZor',
    },
    {
        label: 'Single: $165,000 - $500,000',
        value: 'BTW_160000_500000',
        filingType: 'SINGLE',
        conversationItemId: '7j9vZYC6YDAwCW6wIEg1nh',
        conversationItemOptionId: '3tHOHJpLbgOWRxG9b5I0hH',
    },
    {
        label: 'Single: $500,000 or above',
        value: 'GT_500000',
        filingType: 'SINGLE',
        conversationItemId: '7j9vZYC6YDAwCW6wIEg1nh',
        conversationItemOptionId: '53q5wZbaUolXOs0zpXElW',
    },
    {
        label: 'Jointly: $176,000 or less',
        value: 'LTE_174000',
        filingType: 'JOINTLY',
        conversationItemId: '4B0PrgbuTB65svT0zbhxKu',
        conversationItemOptionId: 'G26vXIKK31NBhrkuZGPd1',
    },
    {
        label: 'Jointly: $176,000 - $222,000',
        value: 'BTW_174000_218000',
        filingType: 'JOINTLY',
        conversationItemId: '4B0PrgbuTB65svT0zbhxKu',
        conversationItemOptionId: '5Lm2CjXD1tgZEB2qFpNsOW',
    },
    {
        label: 'Jointly: $222,000 - $276,000',
        value: 'BTW_218000_272000',
        filingType: 'JOINTLY',
        conversationItemId: '4B0PrgbuTB65svT0zbhxKu',
        conversationItemOptionId: 'xUCIhwV9NgkbsWHfgq8SG',
    },
    {
        label: 'Jointly: $276,000 - $330,000',
        value: 'BTW_272000_326000',
        filingType: 'JOINTLY',
        conversationItemId: '4B0PrgbuTB65svT0zbhxKu',
        conversationItemOptionId: '7wZ3BCwv57kFgv8LEO3QqL',
    },
    {
        label: 'Jointly: $330,000 - $750,000',
        value: 'BTW_326000_750000',
        filingType: 'JOINTLY',
        conversationItemId: '4B0PrgbuTB65svT0zbhxKu',
        conversationItemOptionId: '2mDNWdfwUncgvSnTtnoWrj',
    },
    {
        label: 'Jointly: $$750,000 or greater',
        value: 'GT_750000',
        filingType: 'JOINTLY',
        conversationItemId: '4B0PrgbuTB65svT0zbhxKu',
        conversationItemOptionId: '1m3HOMy352Q42uO1qCTMa8',
    },
];

export const entermedicareTheme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#342996',
            light: '#D2CEED',
            white: '#ffffff',
        },
        secondary: {
            main: '#D22584',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1200,
            xl: 1920,
        },
    },
    typography: {
        h1: {
            fontSize: 48,
            fontFamily: 'Halant, serif',
        },
        h2: {
            fontSize: 36,
            fontFamily: 'Halant, serif',
        },
        h3: {
            fontSize: 28,
            fontFamily: 'Halant, serif',
        },
        h4: {
            fontSize: 20,
            fontFamily: 'Halant, serif',
        },
        subtitle1: {
            fontSize: 24,
            fontFamily: 'Source Sans Pro, sans-serif',
        },
        subtitle2: {
            fontSize: 20,
            fontFamily: 'Source Sans Pro, sans-serif',
        },
        body1: {
            fontSize: 16,
            fontFamily: 'Source Sans Pro, sans-serif',
        },
        body2: {
            fontSize: 12,
            fontFamily: 'Source Sans Pro, sans-serif',
        },
    },
    overrides: {
        MuiTableCell: {
            root: {
                verticalAlign: 'text-top',
            },
        },
    },
});

export const EMColor = {
    scoreCircle: 'rgba(186, 216, 194)',
    potentialCircle: 'rgba(63, 106, 96)',
    scoreFont: 'rgba(63, 106, 96)',
    scoreCardBackground: 'rgb(242,249,245)',
};

export const drawerMarginLeft = 202;
export const MIN_SEARCH_CHARS = 3;
export const EMFadedBlue = '#1F4160';
export const EMYellow = '#ffd757';
