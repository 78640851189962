import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import ConversationQuestions from './Conversation/ConversationQuestions';

const ConversationWrapper = styled.div`
    padding: 30px;
`;

class Conversation extends Component {
    render() {
        return (
            <ConversationWrapper>
                <ConversationQuestions
                    conversation={this.props.conversation}
                    hideFaq={this.props.hideFaq}
                    history={this.props.history}
                />
            </ConversationWrapper>
        );
    }
}

export default withRouter(Conversation);
