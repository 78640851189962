import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import services from '../../../services';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import MaskedInput from 'react-text-mask';
import Input from '../../shared/Input/Input';
import ErrorMessage from '../../shared/ErrorMessage/ErrorMessage';
import { get } from 'lodash';
import { Grid } from '@material-ui/core';
import { getEntry } from '../../../services/contentful';

const DATE_FORMAT = 'MM/DD/YYYY';
const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy');
const TIMEOUT = 1000;

const BirthDate = ({ data, goToNextQuestion, intl, triggerFooterUpdate }) => {
    const [birthDate, setBirthDate] = useState('');
    const [invalidDate, setInvalidDate] = useState(null);
    const { decisionType } = data;
    const [imageUrl, setImageUrl] = useState('');
    const footerProps = {
        hasNextButton: false,
    };

    useEffect(() => {
        const init = async () => {
            triggerFooterUpdate(footerProps);
            console.log('here', data);
            const entry = await getEntry({ id: data.conversationItemId });
            if (get(entry, 'fields.illustrationUrl.fields.file.url')) {
                setImageUrl(
                    `https://${entry.fields.illustrationUrl.fields.file.url}?w=350`
                );
            }
        };
        init();
        // eslint-disable-next-line
    }, []);

    const generateMask = userInput => {
        const monthReg = /^[1-9]\/$/;
        const dayReg = /^\d{2}\/[1-9]\/$/;
        if (userInput && userInput.length === 2 && monthReg.test(userInput)) {
            console.log('caught 1');
            return [/\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
        } else if (
            userInput &&
            userInput.length === 5 &&
            dayReg.test(userInput)
        ) {
            console.log('caught 2');
            return [/\d/, /\d/, '/', /\d/, '/', /\d/, /\d/, /\d/, /\d/];
        } else {
            console.log('caught 3');
            return [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
        }
    };

    const onChangeDate = async e => {
        let {
            target: { value },
        } = e;

        if (!value || (value === birthDate && value.length !== 10)) {
            setBirthDate(value);
            return setInvalidDate(
                intl.formatMessage({
                    id: 'birthDate.numericError',
                })
            );
        }
        if (value && value !== birthDate && invalidDate) {
            setInvalidDate(null);
        }

        // adding a trailing forward slash (only necessary for single digit days/months)
        // necessary because autoCorrectedDatePipe doesn't recognize the datemask additions for appending the forward slash
        if (
            (value &&
                value.length === 2 &&
                ![1, 0].includes(parseInt(value.slice(-1)))) ||
            (value &&
                value.length === 5 &&
                ![1, 0].includes(parseInt(value.slice(-1))))
        ) {
            // If a user types "[1-9]/" then add a zero in front; length 2 is for month, length 5 is for day
            if (value.slice(-1) === '/') {
                if (value.length === 2) {
                    setBirthDate(`0${value}/`);
                } else if (value.length === 5) {
                    // insert a '0' in front of the day
                    setBirthDate(
                        value.substring(0, 2) + '0' + value.substring(2)
                    );
                }
                return;
            } else {
                setBirthDate(`${value}/`);
                return;
            }
        }

        console.log('value.length', value.length);
        if (value.length === 10 && moment(value, DATE_FORMAT).isValid()) {
            const dateToUse = value;
            const birthDate = moment(dateToUse, DATE_FORMAT).format(
                'MM-DD-YYYY'
            );
            const eligibility = await services.conversation.isEligible(
                birthDate
            );

            const replacementKey = '{enrollmentTime}';
            const replacementValue = eligibility.enrollmentStartDate;
            // const nextConvo = data.edges[0].nextConversationItemId;
            const { conversationItemId } = data;
            setTimeout(async () => {
                try {
                    await services.conversation.saveResponse({
                        questionId: conversationItemId,
                        answer: [
                            {
                                conversationItemId,
                                answer: birthDate,
                            },
                        ],
                        replacementKey,
                        replacementValue,
                    });
                } catch (e) {
                    console.log(e);
                }
                const years = moment().diff(
                    moment(birthDate, 'MM-DD-YYYY'),
                    'years'
                );
                let nextConversation;
                if (decisionType !== 'PLANNED_RETIREMENT_DATE') {
                    let matchTerm;
                    if (years > 65) {
                        matchTerm = 'Older than 65';
                        data.edges.forEach(edge => {
                            let parsedMeta = JSON.parse(edge.meta);
                            if (parsedMeta.label === matchTerm) {
                                nextConversation = edge.nextConversationItemId;
                            }
                        });
                    } else {
                        matchTerm = 'Younger than 65';
                        data.edges.forEach(edge => {
                            let parsedMeta = JSON.parse(edge.meta);
                            if (parsedMeta.label === matchTerm) {
                                nextConversation = edge.nextConversationItemId;
                            }
                        });
                    }
                } else {
                    nextConversation = get(
                        data,
                        'edges[0].nextConversationItemId'
                    );
                }
                goToNextQuestion({ id: nextConversation });
            }, TIMEOUT);
        }
    };

    return (
        <Grid container>
            <Grid item sm={6}>
                <MaskedInput
                    mask={generateMask}
                    placeholder={DATE_FORMAT}
                    pipe={autoCorrectedDatePipe}
                    keepCharPositions={true}
                    guide={false}
                    onChange={onChangeDate}
                    placeholderChar={' '}
                    value={birthDate}
                    render={(ref, props) => (
                        <>
                            <Input
                                ref={ref}
                                callback={props.onChange}
                                value={props.valueDefault}
                                focusOnLoad={true}
                                name="birthDay"
                                {...props}
                            />
                            {invalidDate ? (
                                <ErrorMessage
                                    isShown={!!invalidDate}
                                    text={invalidDate ? invalidDate : ''}
                                />
                            ) : null}
                        </>
                    )}
                />
            </Grid>
            <Grid
                item
                sm={6}
                justifyContent="center"
                style={{ textAlign: 'right' }}
            >
                <img src={imageUrl} />
            </Grid>
        </Grid>
    );
};

BirthDate.propTypes = {};
BirthDate.defaultProps = {};

export default withRouter(injectIntl(BirthDate));
