import React, { Component, Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import services from '../../../../services';
import { Grid, TextField } from '@material-ui/core';
import Input from '../../../shared/Input/Input';
import Button from '../../../shared/Button/Button';
import ErrorMessage from '../../../shared/ErrorMessage/ErrorMessage';
import Footer from '../../../shared/Footer/Footer';
import { get } from 'lodash';
import { fetchUser } from '../../../../services/user';
import { init } from '@sentry/react';
import queryString from 'query-string';

const Employer = ({
    data,
    goToNextQuestion,
    intl,
    triggerFooterUpdate,
    accessToken,
    location,
}) => {
    const [employer, setEmployer] = useState('');

    useEffect(() => {
        const footerProps = {
            hasNextButton: true,
            nextCallback: saveSelection,
            nextButtonText: 'Next',
        };
        triggerFooterUpdate(footerProps);
    }, [employer]);

    const init = async () => {
        const accessToken = queryString.parse(location.search).accessToken;
        const user = await fetchUser(accessToken);

        if (get(user, 'employer')) {
            const nextConvo = get(data, 'edges[0].nextConversationItemId');
            goToNextQuestion({ id: nextConvo });
        } else {
            const footerProps = {
                hasNextButton: true,
                nextCallback: saveSelection,
                nextButtonText: 'Next',
            };
            triggerFooterUpdate(footerProps);
        }
    };

    const saveSelection = async () => {
        const { conversationItemId } = data;
        const nextConvo = get(data, 'edges[0].nextConversationItemId');

        try {
            const savedAnswer = {
                questionId: conversationItemId,
                answer: [
                    {
                        conversationItemId,
                        answer: employer,
                    },
                ],
            };
            await services.conversation.saveResponse(savedAnswer);
            goToNextQuestion({ id: nextConvo });
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        // check if user has answered employer question
        init();
    }, []);

    return (
        <div>
            <TextField
                onChange={({ target: { value } }) => {
                    setEmployer(value);
                }}
            />
            <Footer hasNextButton={true} />
        </div>
    );
};

export default withRouter(Employer);
