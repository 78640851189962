import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import SimpleLoading from './SimpleLoading.gif';

const LoadingScreen = ({ animationCompleteCallback }) => {
    const [showLoadingGif, setShowLoadingGif] = useState(false);

    useEffect(() => {
        setShowLoadingGif(true);
        const timer = setTimeout(() => {
            animationCompleteCallback();
        }, 8400);
        return () => clearTimeout(timer);
        //eslint-disable-next-line
    }, []);

    return (
        <LoadingContainer>
            <ContentContainer>
                <Text>{`Now we know what's important to you based on what you've told us.`}</Text>
                <Text>
                    <b>Let us find the plan that fits</b>
                </Text>
                {showLoadingGif ? (
                    <Animation src={SimpleLoading} />
                ) : (
                    // Transparent pixel as placeholder
                    <Animation src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" />
                )}
            </ContentContainer>
        </LoadingContainer>
    );
};

const LoadingContainer = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const ContentContainer = styled.div`
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Text = styled.div`
    text-align: center;
`;

const Animation = styled.img`
    margin-top: 36px;
    width: 48px;
    height: 108px;
    border: none;
    outline: none;
`;

export default LoadingScreen;
