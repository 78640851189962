export const updateProgressBarValue = progressBarValue => ({
    type: 'UPDATE_PROG_VALUE',
    progressBarValue,
});

export const setBrokerId = brokerId => ({
    type: 'SET_BROKER_ID',
    brokerId,
});

export const setAccessToken = accessToken => ({
    type: 'SET_ACCESS_TOKEN',
    accessToken,
});

export const setReferenceCode = referenceCode => ({
    type: 'SET_REFERENCE_CODE',
    referenceCode,
});
