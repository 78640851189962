import React, { Component } from 'react';
import { Paper, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Button from './Button/Button.js';

const styles = () => ({
    paperContainer: {
        width: '50%',
        margin: '0 auto',
        textAlign: 'center',
        padding: 50,
        marginTop: 150,
    },
    reportErrorButton: {
        marginTop: 30,
    },
});

class EMErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { eventId: null };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        const { sentry } = this.props;
        sentry.withScope(scope => {
            scope.setExtras(errorInfo);
            const eventId = sentry.captureException(error);
            this.setState({ eventId });
        });
    }

    render() {
        const { classes, sentry } = this.props;
        if (this.state.hasError) {
            return (
                <Paper className={classes.paperContainer}>
                    <span>
                        {
                            'Uh oh. Looks like you encountered an error. It was reported to the engineering team.'
                        }
                        <br></br>
                        {`Click the button below to report additional details`}
                    </span>
                    <br></br>
                    <Button
                        className={classes.reportErrorButton}
                        color={'primary'}
                        variant={'outlined'}
                        onClick={() =>
                            sentry.showReportDialog({
                                eventId: this.state.eventId,
                            })
                        }
                        text={'Report Feedback'}
                    />
                </Paper>
            );
        }
        return this.props.children;
    }
}

EMErrorBoundary.propTypes = {
    sentry: PropTypes.any.isRequired,
};

export default withStyles(styles)(EMErrorBoundary);
