import API from './api';
import { debounce } from 'lodash';

const submitPersonalData = ({ form, accessToken }) =>
    new Promise((resolve, reject) => {
        API.post(`/customer/create-from-conversation`, form, {
            headers: { Authorization: accessToken },
        })
            .then((res, err) => {
                if (err) {
                    return reject('Error saving personal data');
                }
                return resolve(res.data);
            })
            .catch(err => {
                reject(err.response ? err.response.data : err);
            });
    });

export const fetchProfile = async accessToken => {
    const { data } = await API.get('/employee/profile', {
        headers: { Authorization: accessToken },
    });
    return data;
};

export const fetchDrugs = async accessToken => {
    const { data } = await API.get('/employee/profile/drugs', {
        headers: { Authorization: accessToken },
    });
    return data;
};

export const fetchDoctors = async accessToken => {
    const { data } = await API.get('/employee/profile/doctors', {
        headers: { Authorization: accessToken },
    });
    return data;
};

export const fetchZip = async accessToken => {
    const { data } = await API.get('/employee/profile/zip', {
        headers: { Authorization: accessToken },
    });
    return data;
};

export const fetchUserBroker = async accessToken => {
    const { data } = await API.get('/user/broker', {
        headers: { Authorization: accessToken },
    });
    return data;
};

const throttledSubmitData = debounce(submitPersonalData, 7500, {
    leading: true,
});

export const fetchMonthlyPremium = async accessToken => {
    const { data } = await API.get('/employee/profile/premium', {
        headers: { Authorization: accessToken },
    });
    return data;
};

export const fetchYearlyDeductible = async accessToken => {
    const { data } = await API.get('/employee/profile/deductible', {
        headers: { Authorization: accessToken },
    });
    return data;
};

export const fetchOutOfPocketMax = async accessToken => {
    const { data } = await API.get('/employee/profile/oopm', {
        headers: { Authorization: accessToken },
    });
    return data;
};

export const fetchCurrentPlanName = async accessToken => {
    const { data } = await API.get('/employee/profile/currentPlanName', {
        headers: { Authorization: accessToken },
    });
    return data;
};

export const fetchPocketMax = async accessToken => {
    const { data } = await API.get('/employee/profile/pocketMax', {
        headers: { Authorization: accessToken },
    });
    return data;
};

export const fetchUser = async accessToken => {
    const { data } = await API.get('/customer/conversation-user', {
        headers: { Authorization: accessToken },
    });

    return data;
};

export default {
    throttledSubmitData,
    fetchDrugs,
    fetchDoctors,
    fetchZip,
    fetchUser,
};
