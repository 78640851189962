import React from 'react';
import styled from 'styled-components';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';

const DisclaimerWrapper = styled.p`
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 24px;
`;

const fsUnmask = "fs-unmask";

const Disclaimer = () => (
    <DisclaimerWrapper className={fsUnmask}>
        <FormattedHTMLMessage id="disclaimer" />
    </DisclaimerWrapper>
);

export default injectIntl(Disclaimer);
