import React from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { employeeCenterUrlForEnv } from '../../../utils';

import './index.scss';

export const BackButton = props => {
    const fsUnmask = 'fs-unmask';
    const handleGoBack = () => {
        localStorage.setItem('navigatedBack', '1');
        if (props.triggerAnimation) {
            props.triggerAnimation();
        }
        setTimeout(() => {
            if (props.isFirstQuestion) {
                window.location.href = employeeCenterUrlForEnv();
            } else {
                props.history.goBack();
            }
        }, 500);
    };

    return (
        <button
            className="back-button"
            onClick={props.callback ? props.callback : handleGoBack}
            data-test-id="back-button"
        >
            <span className={'back-button-text ' + fsUnmask}>
                <FormattedMessage id="button.back" />
            </span>
        </button>
    );
};

BackButton.propTypes = {
    callback: PropTypes.func,
};

export default withRouter(BackButton);
