import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/styles';
import Check from './CheckBoxIcon';
import BorderIcon from './BorderIcon';
import { useHover } from '../../utils/customHooks';

const useStyles = makeStyles(() => ({
    label: {
        fontSize: '20px !important',
        lineHeight: '30px !important',
        letterSpacing: '0.5px !important',
        color: '#000000 !important',
        marginLeft: '0 !important',
        alignItems: 'start',
        marginTop: '0 !important',
        padding: '0',
        width: '100%',
    },
    bold: {
        '& + span': {
            fontWeight: '900 !important',
        },
    },
    root: {
        paddingTop: '0',
        paddingLeft: '0',
    },
}));

const CustomCheckbox = ({
    name,
    value,
    onChange,
    label,
    checked,
    disabled,
}) => {
    const classes = useStyles(checked);
    let [hoverRef, isHovered] = useHover();

    return (
        <div ref={hoverRef}>
            <FormControlLabel
                checked={checked ? checked : isHovered}
                classes={{
                    root: classes.root, // class name, e.g. `classes-nesting-root-x`
                    label: classes.label, // class name, e.g. `classes-nesting-label-x`
                }}
                control={
                    <Checkbox
                        data-test="checkbox"
                        classes={{ root: classes.root }}
                        icon={<BorderIcon />}
                        checkedIcon={<Check />}
                        value={value}
                        name={name}
                        onChange={onChange}
                        disabled={disabled}
                    />
                }
                label={label}
            />
        </div>
    );
};

CustomCheckbox.propTypes = {};
CustomCheckbox.defaultProps = {};

export default CustomCheckbox;
