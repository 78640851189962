import { isThirdPerson, getSubdomain } from '../conversationState';

class ConversationItemOption {
    constructor({ entry }) {
        const {
            conversationItemId,
            description,
            description3rdPerson,
            forSomeoneElse,
            isActive,
            nextConversationItemObj,
            option,
            option3rdPerson,
            optionEnum,
            premiumValue,
        } = entry.fields;

        this.conversationItemId = conversationItemId;
        this.description = description
            ? description.content[0].content[0].value
            : '';
        this.description3rdPerson = description3rdPerson
            ? description3rdPerson.content[0].content[0].value
            : '';

        this.forSomeoneElse = forSomeoneElse;
        this.isActive = isActive;

        const subdomain = getSubdomain();
        if (nextConversationItemObj) {
            this.nextConversationItemId = subdomain
                ? nextConversationItemObj[subdomain]
                : nextConversationItemObj['em'];
        }

        this.option = option;
        this.option3rdPerson = option3rdPerson;
        this.optionEnum = optionEnum;
        this.premiumValue = premiumValue;

        this.id = entry.sys.id;
    }

    getOption = () => {
        return isThirdPerson() && this.option3rdPerson
            ? this.option3rdPerson
            : this.option;
    };

    getDescription = () => {
        return isThirdPerson() && this.description3rdPerson
            ? this.description3rdPerson
            : this.description;
    };
}

export default ConversationItemOption;
