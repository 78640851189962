import React, { Component } from 'react';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { withRouter } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import Text from '../Text';

const NumberMaskInput = props => {
    let { inputRef, ...others } = props;
    return (
        <MaskedInput
            {...others}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={createNumberMask()}
            placeholder={'0'}
            showMask={false}
        />
    );
};

class Number extends Component {
    render() {
        return <Text mask={NumberMaskInput} {...this.props} />;
    }
}

export default withRouter(Number);
