import React, { Component } from 'react';
import styled from 'styled-components';
import services from '../../../services';

import ButtonGroup from '../../shared/ButtonGroup/ButtonGroup';

const QuestionWrapper = styled.div``;

class ConversationHostComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            answers: {},
        };

        this.handleButtonClick = this.handleButtonClick.bind(this);

        const footerProps = {
            hasNextButton: false,
        };
        props.triggerFooterUpdate(footerProps);
    }

    async handleButtonClick(subQuestionId, answerId, nextQuestionId) {
        // await setState otherwise race condition with accessing it below
        const { data } = this.props;
        const { conversationItemId } = data;
        const nextConversationItemId = data.edges[0].nextConversationItemId;
        await this.setState({
            answers: {
                ...this.state.answers,
                [subQuestionId]: answerId,
            },
        });

        if (
            Object.keys(this.state.answers).length ===
            this.props.data.options.length
        ) {
            const answerObjs = [];
            Object.keys(this.state.answers).forEach(k => {
                answerObjs.push({
                    conversationItemId: conversationItemId,
                    conversationItemOptionId: this.state.answers[k],
                });
            });

            try {
                await services.conversation.saveResponse({
                    questionId: conversationItemId,
                    answer: answerObjs,
                });
            } catch (e) {
                console.log(e);
            }

            setTimeout(() => {
                this.props.goToNextQuestion({
                    id: nextConversationItemId,
                });
            }, 400);
        } else {
            const nextElement = document.getElementById(nextQuestionId);
            if (nextElement !== null) {
                setTimeout(
                    nextElement.scrollIntoView({ behavior: 'smooth' }),
                    400
                );
            }
        }
    }

    render() {
        return (
            <QuestionWrapper>
                {this.props.data.options.map((q, i) => {
                    const nextQuestionId =
                        i + 1 < this.props.data.options.length
                            ? this.props.data.options[i + 1].id
                            : null;
                    return (
                        <ButtonGroup
                            id={q.id}
                            title={q.name}
                            buttons={q.options}
                            callback={(
                                subQuestionId,
                                answerId,
                                nextQuestionId
                            ) =>
                                this.handleButtonClick(
                                    subQuestionId,
                                    answerId,
                                    nextQuestionId
                                )
                            }
                            questionId={q.id}
                            nextQuestionId={nextQuestionId}
                            buttonSelected={this.state.answers[q.id]}
                            key={q.name}
                        />
                    );
                })}
            </QuestionWrapper>
        );
    }
}

export default ConversationHostComponent;
