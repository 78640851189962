import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

import WomanImage from './woman.svg';
import ManImage from './man.svg';

function ErrorPage({ paragraphs }) {
    return (
        <div className="error-page">
            <h2>404!</h2>

            <div className="error-page-content">
                <img
                    className="left-img"
                    src={WomanImage}
                    alt="Woman holding a fan"
                />
                <div className="text-wrapper">
                    {paragraphs.map(paragraph => (
                        <p key={paragraph}>{paragraph}</p>
                    ))}
                </div>
                <img
                    className="right-img"
                    src={ManImage}
                    alt="Man holding a cane"
                />
            </div>
        </div>
    );
}

ErrorPage.propTypes = {
    paragraphs: PropTypes.arrayOf(PropTypes.string),
};

export default ErrorPage;
