import queryString from 'query-string';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import InfoPage from '../shared/InfoPage/InfoPage';
import HostComponent from './ConversationHostComponent';
import PersonalDataComponent from './PersonalDataComponent';

import ErrorPage from '../../pages/ErrorPage/ErrorPage';
import ReviewInfo from '../../pages/ReviewInfoPage/ReviewInfoPage';
import { errorPageParagraphs } from '../utils/error-page-text';
import { getConversationRouting } from '../../services/conversation';

class ConversationQuestions extends Component {
    state = {
        conversationRouting: {},
    };

    async componentDidUpdate(prevProps) {
        if (prevProps.conversation.id !== this.props.conversation.id) {
            const conversationRouting = await getConversationRouting(
                this.props.conversation.id
            );
            this.setState({ conversationRouting });
        }
    }

    render() {
        return (
            <Switch>
                <Route
                    path="/conversation/ineligible-county"
                    component={props => (
                        <InfoPage
                            headline={
                                <FormattedMessage
                                    id="infoPage.countyEligibility.headline"
                                    values={{
                                        county: queryString.parse(
                                            props.location.search
                                        ).county,
                                    }}
                                />
                            }
                            paragraph={
                                <FormattedMessage id="infoPage.countyEligibility.paragraph" />
                            }
                        />
                    )}
                />
                <Route
                    path="/conversation/special-eligibility"
                    component={() => (
                        <InfoPage
                            headline={
                                <FormattedMessage id="infoPage.specialEligibility.headline" />
                            }
                            paragraph={
                                <FormattedMessage id="infoPage.specialEligibility.paragraph" />
                            }
                        />
                    )}
                />
                <Route
                    path={`/conversation/personal-data`}
                    component={PersonalDataComponent}
                />
                <Route
                    exact
                    path="/conversation/review-your-info"
                    component={() => <ReviewInfo nextButtonText="Yes" />}
                />
                <Route
                    path={`/conversation/question/:id`}
                    component={() => {
                        const { conversation, history } = this.props;
                        const { conversationRouting } = this.state;
                        return (
                            <HostComponent
                                conversation={conversation}
                                conversationRouting={conversationRouting}
                                history={history}
                                hideFaq={this.props.hideFaq}
                            />
                        );
                    }}
                />
                <Route
                    component={() => (
                        <ErrorPage paragraphs={errorPageParagraphs} />
                    )}
                />
            </Switch>
        );
    }
}

export default withRouter(ConversationQuestions);
