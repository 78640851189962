import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '../Button/Button';

const ButtonGroupTitle = styled.h3`
    font-weight: bold;
    font-family: Le Monde Livre Pro;
    font-size: 28px;
    line-height: 42px;
    color: #041f1e;
    margin-bottom: 20px;
`;

const ButtonGroupWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
`;

const ButtonGroup = ({
    id,
    title,
    buttons,
    callback,
    buttonSelected,
    questionId,
    nextQuestionId,
}) => (
    <ButtonGroupWrapper id={id} className="button-group-wrapper">
        {title && (
            <ButtonGroupTitle className="title">{title}</ButtonGroupTitle>
        )}
        <ButtonWrapper className="button-wrapper">
            {buttons.map((button, i) => (
                <Button
                    buttonType="rect-transparent"
                    customClass={buttonSelected === button.id ? 'clicked' : ''}
                    key={i}
                    text={button.option}
                    size="large"
                    callback={() =>
                        callback(questionId, button.id, nextQuestionId)
                    }
                />
            ))}
        </ButtonWrapper>
    </ButtonGroupWrapper>
);

ButtonGroup.propTypes = {
    title: PropTypes.string,
    buttons: PropTypes.array,
    callback: PropTypes.func,
    buttonSelected: PropTypes.string,
};

export default ButtonGroup;
