import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const Button = ({
    text,
    name,
    callback,
    buttonType,
    size,
    customClass,
    disabled,
    style,
}) => {
    const [mouseDownClass, handleOnMouseDownClass] = useState('');

    return (
        <button
            name={name}
            className={`${buttonType} ${size} ${customClass} ${mouseDownClass} ${
                disabled ? 'disabled' : ''
            }`}
            data-test-id="actionButton"
            onClick={callback}
            onMouseDown={() => handleOnMouseDownClass('clicked')}
            onMouseUp={() => handleOnMouseDownClass('')}
            disabled={disabled}
            style={style ? style : undefined}
            type="button"
        >
            {text}
        </button>
    );
};

Button.propTypes = {
    text: PropTypes.oneOfType([
        PropTypes.object, // object b/c it could be a react-intl FormattedMessage
        PropTypes.string,
    ]),
    callback: PropTypes.func,
    buttonType: PropTypes.oneOf([
        'rounded-purple',
        'rounded-white',
        'rounded-transparent',
        'rounded-transparent-white',
        'rect-transparent',
    ]).isRequired,
    size: PropTypes.oneOf(['small', 'large', 'medium']).isRequired,
    customClass: PropTypes.string,
    disabled: PropTypes.bool,
};

export default Button;
